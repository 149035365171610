import React from 'react'

const FacturasPendientes = () => {
  return (
    <div>
      FacturasPendientes
    </div>
  )
}

export default FacturasPendientes
