import React from 'react'

const SolicitudesProveedores = () => {
  return (
    <div>
      SolicitudesProveedores
    </div>
  )
}

export default SolicitudesProveedores
