import React from 'react'

const ReporteRecoleccionManifiesto = () => {
  return (
    <div>
      Report eRecoleccion Manifiesto
    </div>
  )
}

export default ReporteRecoleccionManifiesto
