import React from "react";
import AltaFrecuenciaForm from "./AltaFrecuencia.Form";
import CardNavigate from "../../../componentes/Cards/CardNavigate";

const AltaFrecuencia = () => {
  const tituloAFrec = "Alta de Frecuencia de Visitas a Clientes";
  return (
    <CardNavigate title={tituloAFrec}>
      <AltaFrecuenciaForm />
    </CardNavigate>
  );
};

export default AltaFrecuencia;
