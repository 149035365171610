//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarInsumo } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Insumos", successText: 'Insumo agregado correctamente.' }

export const initValuesAi = {
    clave: "8030",
    nombre: "",
    modelo: "",
    uni: "",
    tipo: "",
    precio: "",
    minimo: "",
    maximo: ""
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato insumos: ");
}

export async function CrearInsumo(values) {
    console.log("onsubmit....");
    const datosInsu = {
        clave: values.clave,
        nombre: values.nombre,
        modelo: values.modelo,
        uni: values.uni,
        tipo: values.tipo,
        precio: values.precio,
        minimo: values.minimo,
        maximo: values.maximo,
    };
   // await sleep(5000);
    await AgregarInsumo(datosInsu);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/insumos";
