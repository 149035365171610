// Initialization for ES Users
import { Collapse, Ripple, initTE } from "tw-elements";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { deleteItem, getItem } from "../../utils/LocalStorage";

export default function InternalHeader({ hiddenMenu, setMenuExpanded }) {
  const [userName] = useState(getItem("userName"));
  const navegar = useNavigate();

  function LogOut() {
    console.log("Click cerrandoSesion....");
    deleteItem("user");
    deleteItem("userName");
    navegar("/suvalsa/login");
  }

  useEffect(() => {
    initTE({ Collapse, Ripple });
  }, []);

  return (
    <header className="flex flex-col lg:flex-row justify-between w-full gap-2">
      <div className="flex justify-between md:justify-start items-baseline gap-4">
        {hiddenMenu &&
          <button
            className='ml-0 mt-2 rounded bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-500 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg'
            data-te-sidenav-toggle-ref
            data-te-target="#sideNavMenu"
            aria-controls="#sideNavMenu"
            aria-haspopup="true"
            onClick={(e) => {
              e.preventDefault()
              setMenuExpanded(hiddenMenu)
            }}
          >
            <span className="block [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
        }
      <div>
        <span className="font-normal text-md md:text-lg">Bienvenido a Suvalsa </span>
        <span className="font-bold text-lg md:text-2xl">{userName}</span>
      </div>
      </div>

      <nav
        className="relative flex justify-end bg-transparent text-neutral-600 focus:text-neutral-700 dark:bg-neutral-600 dark:text-neutral-200 md:flex-wrap"
        data-te-navbar-ref
      >
        <div className="flex md:w-full flex-wrap items-center justify-between">
          <div
            className="!visible grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContentY"
            data-te-collapse-item
          >
            <ul
              className="mr-auto flex flex-row gap-4 items-center justify-end"
              data-te-navbar-nav-ref
            >
              <li className="mb-2 lg:mb-0" data-te-nav-item-ref>
                <button
                  onClick={() => navegar("/suvalsa/home")}
                  className="block transition duration-150 ease-in-out hover:text-primary-600 focus:text-neutral-700 disabled:text-black/30 dark:hover:text-white dark:focus:text-white  [&.active]:text-black/90 cursor-pointer"
                  data-te-nav-link-ref
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Dashboard Principal
                </button>
              </li>

              <li className="mb-2 lg:mb-0 pr-2" data-te-nav-item-ref>
                <button
                  onClick={() => LogOut()}
                  className="block transition duration-150 ease-in-out hover:text-primary-600 focus:text-neutral-700 disabled:text-black/30 dark:hover:text-white dark:focus:text-white  [&.active]:text-black/90 cursor-pointer"
                  data-te-nav-link-ref
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Cerrar Sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
