import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaRecRuta = Yup.object({
  ruta: Yup.string().required(MSJ_DATO_REQ),
  nombre: Yup.string(),
  fInicio: Yup.string().required(MSJ_DATO_REQ),
  fEnvio: Yup.string().required(MSJ_DATO_REQ),
  dias: Yup.string(),
  operador: Yup.string(),
  auxiliar: Yup.string(),
  vehiculo: Yup.string(),
});

export default schemaAltaRecRuta;
