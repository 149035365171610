import { persist } from "zustand/middleware";
import { ObtenerSalidasManif } from "../Apis/FetchSalidas";
import { ActualizarManifiesto } from "../Apis/FetchManifiestos";
import dayjs from "dayjs";

const createSalidasManifiesto = (salida) => {
    return { ...salida }
}


export const createSalidasManifiestoSlice = persist((set, get) => ({
    salidas: [],
    showSalidas: true,
    saveSalidas: async(noSalida, noManifiesto, bodyGenManif) => {
        console.log('saligar a guardar...')
        console.log(get().salidas)
        const arayDataSalidas=[];
        get().salidas.forEach(async (sal) => {
            const bodyData = {
                numero: noSalida,
                fecha: dayjs(bodyGenManif.fruta).format("YYYY-MM-DD"),//"2024-05-10",
                tipo: bodyGenManif.tipo,
                insumo: sal.insumoId,
                cantidad: sal.cantidad,
                unidad: sal.unidad,
                referencia: "",
                familia: "",
                cliente: sal.clave,
                manif: noManifiesto,
                factura: "",
                usom: "",
                status: ""
              };
              console.log(bodyData)
              arayDataSalidas.push(bodyData)
        })

        const bodyRequestSalidasManif={
            manifDetails: bodyGenManif,
            salidas: arayDataSalidas
        }
        
        await ActualizarManifiesto(noSalida, noManifiesto,bodyRequestSalidasManif)
    },
    getSalidas: async (noSalida, noManifiesto) => {
        const data = noSalida && noManifiesto ? await ObtenerSalidasManif(noSalida, noManifiesto) : [];
        let cSalidas = [];
        if (data !== null && data.length > 0) {
            cSalidas = data.map((res) => {
                const insumo = {
                    id: res.salida.id,
                    clave: res.salida.insumo,
                    insumo: res.modelo,
                    cantidad: res.salida.cantidad,
                    cantidadActual: res.salida.cantidad,
                    //tipo: res.tipo,
                    unidad: res.salida.unidad,
                    insumoId : res.salida.insumo
                };
                return insumo;
            });
        }
        set(() => ({
            salidas: cSalidas
        }))
        return cSalidas;
    },

    setSalidas: (data) => {
        set(() => ({
            salidas: data
        }))
    },

    addSalida: (data) => {
        const newSalida = createSalidasManifiesto(data)
        if (get().salidas.some(s => s.clave === data.clave)) {
            get().updateSalida(data)
        } else {
            set((state) => ({
                salidas: [...state.salidas, newSalida]
            }))
        }
    },

    deleteSalida: (clave) => {
        set((state) => ({
            salidas: state.salidas.filter(salida => salida.clave !== clave)
        }))
    },

    updateSalida: (data) => {
        set((state) => ({
            salidas: state.salidas.map(salida => salida.clave === data.clave ? { ...data, cantidad: data.cantidad } : salida)
        }))
    },

    setShowSalidas: (flag) => {
        set((state) => ({
            showSalidas: flag,
            salidas: state.salidas
        }))
    }
}),
    {
        name: 'salidas-manifiesto-storage'
    })