// MyMap.js
import React, { useEffect, useRef, useState } from 'react';
import { ObtenerVehiculosApi, ObtenerDetalleVehiculoApi, ObtenerEventosVehiculo } from '../../Apis/FetchCatalogos';

const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
};

const MyMap = () => {
    const mapRef = useRef(null);
    const [mapInstance, setMapInstance] = useState(null);
    const [vehiculos, setVehiculos] = useState([]);
    const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState('');
    const [vehiculosConDetalles, setVehiculosConDetalles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [posicionActual, setPosicionActual] = useState(null);
    const [marker, setMarker] = useState(null);

    useEffect(() => {
        const cargarVehiculos = async () => {
            setIsLoading(true);
            try {
                const listaVehiculos = await ObtenerVehiculosApi();
                setVehiculos(listaVehiculos);

                const detallesPromises = listaVehiculos.map(vehiculo =>
                    ObtenerDetalleVehiculoApi(vehiculo.Id)
                );

                const todosLosDetalles = await Promise.all(detallesPromises);

                const vehiculosCompletos = listaVehiculos.map((vehiculo, index) => ({
                    ...vehiculo,
                    detalles: todosLosDetalles[index]
                }));

                setVehiculosConDetalles(vehiculosCompletos);
            } catch (error) {
                console.error("Error al cargar vehículos:", error);
            } finally {
                setIsLoading(false);
            }
        };

        cargarVehiculos();

        if (typeof window.google === 'undefined') return;

        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 19.42552788710817, lng: -99.09006396360215 },
            zoom: 13,
        });
        setMapInstance(map);
    }, []);

    const handleVehiculoChange = async (e) => {
        const selectedId = e.target.value;
        setVehiculoSeleccionado(selectedId);

        if (selectedId) {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Mes en formato 2 dígitos
            const day = String(today.getDate()).padStart(2, '0'); // Día en formato 2 dígitos

            const startDate = `${year}${month}${day}T000000`; // Fecha de inicio
            const endDate = `${year}${month}${day}T235900`; // Fecha de fin

            try {
                const eventos = await ObtenerEventosVehiculo(selectedId, startDate, endDate);

                if (eventos && eventos.length > 0) {
                    // Ordenar eventos por LocalTimestamp y tomar el último
                    const ultimoEvento = eventos.sort((a, b) =>
                        new Date(b.LocalTimestamp) - new Date(a.LocalTimestamp)
                    )[0];

                    const { Position } = ultimoEvento;
                    const posicion = { lat: Position[1], lng: Position[0] };

                    // Actualizar la posición actual
                    setPosicionActual(posicion);

                    // Agregar marcador o actualizar su posición
                    if (marker) {
                        marker.setPosition(posicion);
                    } else {
                        const newMarker = new window.google.maps.Marker({
                            position: posicion,
                            map: mapInstance,
                            title: "Última posición del vehículo",
                        });
                        setMarker(newMarker);
                    }

                    // Centrar el mapa en la última posición
                    mapInstance.setCenter(posicion);
                }
            } catch (error) {
                console.error("Error al obtener eventos del vehículo:", error);
            }
        }
    };

    const currentYear = new Date().getFullYear();
    const vehiculosActivos = vehiculosConDetalles.filter(vehiculo => {
        const lastUpdateYear = new Date(vehiculo.detalles.UtcLastUpdate).getFullYear();
        return lastUpdateYear === currentYear;
    });

    return (
        <>
            <select
                value={vehiculoSeleccionado}
                onChange={handleVehiculoChange}
                style={{ marginBottom: '10px' }}
                disabled={isLoading}
            >
                <option value="">
                    {isLoading
                        ? "Cargando vehículos..."
                        : `Seleccione un vehículo (${currentYear})`
                    }
                </option>
                {!isLoading && vehiculosActivos.map((vehiculo) => (
                    <option key={vehiculo.Id} value={vehiculo.Id}>
                        {vehiculo.detalles.Make} - {vehiculo.detalles.Registration}
                    </option>
                ))}
            </select>
            <div ref={mapRef} style={mapContainerStyle}></div>
            {posicionActual && (
                <p>Última posición: Lat {posicionActual.lat}, Lng {posicionActual.lng}</p>
            )}
        </>
    );
};

export default MyMap;
