import React, { useEffect } from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import IncorporacionesRecolecRutaForm from "./AltaRecoleccionesRuta.Form";
// Initialization for ES Users
import { Ripple, initTE } from "tw-elements";
import Accordion from "../../../componentes/Accordion/Accordion";
import useRecoleccionesEnviadas from "../UseRecolecciones";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";

const DatosRutasRecolecciones = () => {
  const { loading, tituloRecoleccionesEnv, optionsTable, columnsData, data, setData, GetRowsData } =
    useRecoleccionesEnviadas();

  useEffect(() => {
    initTE({ Ripple });
  }, []);

  return (
    <>
      {(loading ) && <CircleSpiner />}
      <CardNavigate title="Incorporación de Recolecciones en Ruta">
        <IncorporacionesRecolecRutaForm />
        <Accordion
          titulo={tituloRecoleccionesEnv}
          idTarget="collapseResumenManifEnv"
          expanded="true"
        >
          <div className="flex flex-col w-full gap-2">
            <CustomGenericTable
              data={data}
              setData={setData}
              getData={GetRowsData}
              optionsTable={optionsTable}
              columnsData={columnsData}
            />
          </div>
        </Accordion>
      </CardNavigate>
    </>
  );
};

export default DatosRutasRecolecciones;
