import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import useCatalogo from "../../../Hooks/UseCatalogos";
import useCatTrabajosRealizadosfrom from "./UseTrabajos";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoTrabajos = () => {
  const {
    tituloCatTrabajos,
    idModalDelete,
    pathAltaTrab,
    pathCatTrab,
    columnsData,
    opcionesTabla,
    GetTrabajos,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = useCatTrabajosRealizadosfrom();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: trabajos,
    setData: setTrabajos,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetTrabajos,
    idModalDelete,
    pathNavTo: pathAltaTrab,
    pathNavBack: pathCatTrab,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}

      <CardNavigate title={tituloCatTrabajos}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={trabajos}
            setData={setTrabajos}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar Trabajo"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Trabajo con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoTrabajos;
