import React from "react";
import AltaInsumosForm from "./AltaInsumos.Form";
import CardNavigate from "../../../componentes/Cards/CardNavigate";

const AltaInsumos = () => {
  const tituloAInsumo = "Alta de Insumos";

  return (
    <CardNavigate title={tituloAInsumo}>
      <AltaInsumosForm  />
    </CardNavigate>
  );
};

export default AltaInsumos;
