import React from "react";
// Initialization for ES Users
//import { Ripple, initTE } from "tw-elements";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import ActualizarManifiestosForm from "./ActualizarManifiestos.Form";

const ActualizarManifiestos = () => {
  // useEffect(() => {
  //   initTE({ Ripple });
  // }, []);

  return (
    <>
      <CardNavigate title="Actualización de Manifiestos y Documentación de Viaje">
        <ActualizarManifiestosForm />
      </CardNavigate>
    </>
  );
};

export default ActualizarManifiestos;
