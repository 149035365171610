import { useCallback, useMemo, useState } from "react";
import { EliminarResiduo, ObtenerResiduos } from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";

const useCatResiduos = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatResiduos = "Catálogo de Residuos";
  const idModalDelete = "staticBackdrop";
  const pathAltaResid = "/suvalsa/catalogos/residuos/alta";
  const pathCatResid = "/suvalsa/catalogos/residuos";

  const title = "Eliminar Residuo";
  const pendingText = "Eliminando residuo, por favor espere.";
  const successText = `Residuo ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  const columnsData = useMemo(() => 
        [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "uni",
        header: "Uni",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "tipo",
        header: "Tipo",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "titulo",
        header: "Título",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "clasificacion",
        header: "Clasificación",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "clvine",
        header: "Clv.INE",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "sistemadfinal",
        header: "Sistema D.Final",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "empresadfinal",
        header: "Empresa D.Final",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "autorizacion",
        header: "Autorización",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "codigo",
        header: "Código",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],[]);

  const opcionesTabla = { ofText: "Residuos de" };

  const GetResiduos = useCallback(async () =>{
    let rowsNew = [];
    console.log("obteniendo Datos de ws Residuos");
    const residuos = await ObtenerResiduos();
    console.log("lista de Residuos: ", residuos);

    if (residuos) {
      rowsNew = residuos.map((res) => ({
        clave: res.clave,
        nombre: res.nombre,
        uni: res.unidad,
        tipo: res.tipo,
        titulo: res.titulo,
        clasificacion: res.repine1,
        clvine: res.repine2,
        sistemadfinal: res.repine3,
        empresadfinal: res.repine4,
        autorizacion: res.repine5,
        codigo: res.codpel,
        id: res.id
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarResiduo(id);
  }

  return {
    tituloCatResiduos,
    idModalDelete,
    pathAltaResid,
    pathCatResid,
    columnsData,
    opcionesTabla,
    GetResiduos,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
};

export default useCatResiduos;
