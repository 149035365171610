
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomGenericTable } from '../../../../componentes/Tables/CustomTable'
import { columnSize } from '../../../../Constantes';
import {
    ObtenerClientesRuta
} from "../../../../Apis/FetchRutas";

import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import { CustomInputText } from '../../../../componentes/Formik/InputText';

export default function ClientesRutaTable({ clave, autoLoad = true, isSelected = false, multiSelected = false,setDataRowSelection, customColumnFilter }) {
    const [loadingPage, setLoadingPage] = useState(false);

    //clients en ruta
    const [clientesRuta, setClientesRuta] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [columnCustomFiltersClients, setCustomColumnFiltersClients] = useState("");

    const opcionesTabla = { ofText: "Clientes en Ruta de" };

    const columnas = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
                //footer: props => props.column.id,
                //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
            },
            // {
            //     accessorKey: "orden",
            //     header: "Ord.",
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     size: 50 //set column size for this column
            //     //footer: props => props.column.id,
            // },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
                //filterFn: 'customFilter', //using our custom function filter
                //filterFn: "fuseFilterFn"
                //footer: props => props.column.id,
                size: 50, //set column size for this column
                enableSorting: true
            },
            {
                accessorKey: "nombre",
                header: "Nombre",
                enableColumnFilter: false,
                size: columnSize, //set column size for this column
                enableSorting: false
                //footer: props => props.column.id,
            },
            {
                accessorKey: "familia",
                header: "Familia",
                enableColumnFilter: false,
                enableSorting: false
                //footer: props => props.column.id,
            },
            {
                accessorKey: "ciudad",
                header: "Ciudad",
                enableColumnFilter: false,
                enableSorting: false,
                //footer: props => props.column.id,
                size: 100 //set column size for this column
            },
            {
                accessorKey: "servicio",
                header: "Servicio",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100 //set column size for this column
                //footer: props => props.column.id,
            }
        ],
        []
    );

    useEffect(() => {
        if (autoLoad)
            getDataFunction(clave);
    }, [autoLoad, clave]);

    const loadClientesRuta = useCallback(async(clave) => {
        const clientesRuta = await ObtenerClientesRuta(clave);
        let cRuta = [];
        if (clientesRuta) {
            cRuta = clientesRuta.map((r) => ({
                orden: r.orden,
                clave: r.cliente,
                nombre: r.nombre,
                familia: r.fam,
                ciudad: r.ciudad,
                servicio: r.serv,
                id: r.id
            }));
        }
        setClientesRuta(cRuta);
    }, [clave])

    async function getDataFunction(clave) {
        setLoadingPage(true);
        await loadClientesRuta(clave);
        setLoadingPage(false);
    }

    return (
        <>
            {(loadingPage) && <CircleSpiner />}
            {!customColumnFilter &&
                <div className="w-full md:w-1/4">
                    <CustomInputText
                        disabled={loadingPage}
                        label="Buscar por clave"
                        name="claveSearch"
                        type="number"
                        placeholder=""
                        onChange={async (e) => {
                            setCustomColumnFiltersClients([
                                {
                                    id: "clave",
                                    value: e.target.value
                                }
                            ]);
                        }}
                    />
                </div>
            }
            <CustomGenericTable
                data={clientesRuta}
                setData={setClientesRuta}
                getData={() => getDataFunction(clave)}
                optionsTable={opcionesTabla}
                columnsData={columnas}
                //defaultColumn={isEditable ? defaultColumnInsumos : {}}
                columnCustomFilters={customColumnFilter ? customColumnFilter : columnCustomFiltersClients}
                isTableSelected={isSelected}
                isMultiRowSelection={multiSelected ?? false}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                setDataRowSelection={setDataRowSelection}
            />
        </>

    )
}
