import React, { useEffect } from 'react'
// Initialization for ES Users
import { Ripple, initTE } from "tw-elements";
import AltaSalidaAlmacenForm from './AltaSalidaAlmacen.Form'
import CardNavigate from '../../../../componentes/Cards/CardNavigate'

const AltaSalidaAlmacen = () => {
useEffect(() => {
    initTE({ Ripple });
  }, []);
  return (
    <>
    <CardNavigate title="Alta de Paquetes de Salidas del Almacén">
      <AltaSalidaAlmacenForm />
    </CardNavigate>
  </>
  )
}

export default AltaSalidaAlmacen
