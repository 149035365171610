import React from "react";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import { InputText } from "../../../componentes/Formik";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import Accordion from "../../../componentes/Accordion/Accordion";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import schemaAltaFrecuencias from "../Schemas/SchemaAltaFrecuencia";
import {
  initValuesAfv,
  CrearFrecuenciaVisita,
  urlCatalogo,
  notification
} from "./AltaFrecuenciaVisitas";
import useAlta from "../../../Hooks/UseAltas";

const AltaFrecuenciaForm = () => {
  const location = useLocation();

  // get urlBack
  let urlBack;
  if (location.state) {
    urlBack = location.state.urlBack;
  }

  const { loadingAction: loading, onSubmitAlta: onSubmitAltaFrecuencia, buttonsGroup } = useAlta({
    addData: CrearFrecuenciaVisita,
    notification,
    backUrl: urlBack ?? urlCatalogo
  });
  return (
    <>
      {loading && <CircleSpiner />}
      <Formik
        initialValues={initValuesAfv}
        validationSchema={schemaAltaFrecuencias}
        onSubmit={onSubmitAltaFrecuencia}
      >
        {(props) => {
          const { isSubmitting } = props;
          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos de la Frecuencia"
                idTarget="collapseFrecForm"
                expanded="true"
              >
                <div className=" grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full">
                  <InputText
                    disabled={isSubmitting}
                    label="Clave"
                    name="clave"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting}
                    label="Frecuencia"
                    name="frecuencia"
                    type="text"
                    placeholder=""
                  />
                </div>
              </Accordion>

              <ButtonGroup
                buttonPrimary={buttonsGroup.buttonPrimary}
                buttonSecondary={buttonsGroup.buttonSecondary}
                buttonTertiary={buttonsGroup.buttonTertiary}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AltaFrecuenciaForm;
