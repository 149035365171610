import React from 'react'

const EntradasAlmacen = () => {
  return (
    <div>
      EntradasAlmacen
    </div>
  )
}

export default EntradasAlmacen
