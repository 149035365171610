import React from 'react'

const ActualizarRecolecciones = () => {
  return (
    <div>
      ActualizarRecolecciones
    </div>
  )
}

export default ActualizarRecolecciones
