import * as Yup from "yup";

const MSJ_DATO_REQ= 'Dato requerido';
const regexDigit = /^[0-9]+$/;

const schemaAltaProveedor = Yup.object({
  clave: Yup.string()
    .matches(regexDigit, "Clave incorrecta")
    .required(MSJ_DATO_REQ),
  empresa: Yup.string()
  .required(MSJ_DATO_REQ),
  calle: Yup.string()
  .required(MSJ_DATO_REQ),
  colonia: Yup.string()
  .required(MSJ_DATO_REQ),
  ciudad: Yup.string()
  .required(MSJ_DATO_REQ),
  cp: Yup.string()
  .required(MSJ_DATO_REQ),
  contacto: Yup.string()
  .required(MSJ_DATO_REQ),
  telefono: Yup.string()
  .required(MSJ_DATO_REQ),
});

export default schemaAltaProveedor;
