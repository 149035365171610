import React from 'react'

const DepositosRealizados = () => {
  return (
    <div>
      DepositosRealizados
    </div>
  )
}

export default DepositosRealizados
