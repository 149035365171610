import { useCallback, useMemo, useState } from "react";
import { Modal, initTE } from "tw-elements";
import { EliminarInsumo, ObtenerInsumos } from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";
//import { sleep } from "../../../utils/CommonUtils";

const useCatInsumos = () => {
  initTE({ Modal });

  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatInsumos = "Catálogo de Insumos";
  const idModalDelete = "staticBackdrop";
  const pathAltaIns = "/suvalsa/catalogos/insumos/alta";
  const pathCatIns = "/suvalsa/catalogos/insumos";

  const title = "Eliminar Insumo";
  const pendingText = "Eliminando insumo, por favor espere.";
  const successText = `Insumo ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "modelo",
        header: "Modelo",
        enableColumnFilter: false,
        enableSorting: true,
        size: 50 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "uni",
        header: "Uni",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "tipo",
        header: "Tipo",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "precio",
        header: "Precio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "minimo",
        header: "Mínimo",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "maximo",
        header: "Máximo",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Insumos de" };

  const GetInsumos = useCallback(async () =>{
    let rowsNew = [];
    const insumos = await ObtenerInsumos();
    console.log("lista de Insumos: ", insumos);

    if (insumos) {
      rowsNew = insumos.map((ins) => ({
        clave: ins.clave,
        nombre: ins.nombre,
        modelo: ins.modelo,
        uni: ins.unidad,
        tipo: ins.tipo,
        precio: ins.precio,
        minimo: ins.minimo,
        maximo: ins.maximo
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarInsumo(id);
  }

  return {
    tituloCatInsumos,
    idModalDelete,
    pathAltaIns,
    pathCatIns,
    columnsData,
    opcionesTabla,
    dataRowSelection,
    setDataRowSelection,
    GetInsumos,
    actionDelete,
    notification
  };
};

export default useCatInsumos;
