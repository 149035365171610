import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import AltaTrabajoForm from "./AltaTrabajos.Form";

const AltaTrabajos = () => {
  const tituloATrabajo = "Alta de Trabajo Realizado";
  return (
    <CardNavigate title={tituloATrabajo}>
      <AltaTrabajoForm  />
    </CardNavigate>
  );
};

export default AltaTrabajos;
