import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { useTable } from "../../../componentes/Tables/useTable";
import CustomTable from "../../../componentes/Tables/CustomTable";
import CustomToastify from "../../../componentes/Toasts/CustomToast";

const ActManifiestosTable = ({
  clave,
  fInicio,
  columnsData,
  columnCustomFilters,
  rutaData,
  data,
  rowSelection,
  setRowSelection,
  setData,
  getData,
}) => {
  const isTableSelected = true;
  //const [table, setTable] = useState();

  const [globalFilter,] = React.useState("");
  const [columnFilters,] = useState("");
  // {
  //   id: 'clave',
  //   asc: true, // sort by clave in asc order by default
  // },

  const [sorting, setSorting] = React.useState([
    {
      id: "clave",
      desc: "true"
    }
  ]);

  const listenerValidaNumber = (e) => {
    let isDataValid = false;
    let curval = Number(e.target.value);
    setTimeout(function () {
      // revisar si el valor de dias es mayor a 0 y menor a los dias establecidos en la ruta
      if (curval > 0 && curval <= rutaData.duracion.current) {
        isDataValid = true;
      }
      if (isDataValid) {
        console.log("valor numerico permitido");
        e.target.classList.remove("border-[1px]");
        e.target.classList.remove("border-red-600");
      } else {
        console.log(
          "valor no permitido. El valor debe ser numerico y mayor a 0 y menor a los dias establecidos en la ruta"
        );
        e.target.innerHTML = "";
        e.target.classList.add("border-[1px]");
        e.target.classList.add("border-red-600");
        if (curval > rutaData.duracion.current) {
          //notify("El valor debe ser mayor a 0 y menor a los días establecidos en la ruta", "Día incorrecto")
          CustomToastify({
            title: "Día incorrecto", message: "El valor debe ser menor a los días establecidos en la ruta", type: 'warning'
          })
        }
      }
    }, 100);
  };

  const TableCell = (getValue, row, id, table, fInicio) => {
    const [value, setValue] = useState("");
    let initialValue = getValue();

    const isDia = id === "dia";
    const servicio = row.original.servicio;

    const isEditable = id === "rutaplanta" || (
        id === "manifiesto" && !(
          servicio === "CB" ||
          servicio === "CI" ||
          servicio === "TI")
      );
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      if (isDia) {
        const date = dayjs(fInicio);
        const days = Number(value);
        if (isNaN(days) || (!isNaN(days) && (days <= 0 || days > rutaData.duracion.current))) row.original.fvisita = "";
        else
          row.original.fvisita = date
            .add(days - 1, "days")
            .format("YYYY-MM-DD");
      }
      table.options.meta?.updateData(row.index, id, value);
    };
    //establecer el valor de inicio, cuando se recuperan del servicio
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    //dependiendo de la validacion se setea como input o no
    return isDia ? (
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          listenerValidaNumber(e);
        }}
        onBlur={onBlur}
        type={"number"}
        className=" w-16"
      />
    ) : isEditable ? (
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={onBlur}
        type={"text"}
        className=" w-16"
      />
    ) : (
      <span>{value}</span>
    );
  };

  const defaultColumn = {
    cell: ({ getValue, row, column: { id }, table }) => {
      return TableCell(getValue, row, id, table, fInicio.current ?? fInicio);
    }
  };

  // function conditionalSelection(row) {
  //   return row.original.salida === "" || row.original.salida === null;
  // }

  const { table } = useTable({
    isSelected: isTableSelected,
    //isMultiRowSelection: true,
    setData,
    data,
    rowSelection,
    setRowSelection,
    columns: columnsData,
    defaultColumn,
    globalFilter,
    //conditionalSelection,
    columnFilters: columnCustomFilters ?? columnFilters,
    sorting,
    setSorting
  });

  // const crearFiltro = () => {
  //   const headerCl = table.getHeaderGroups().at(0).headers[1];
  //   console.log(headerCl);
  //   return (
  //     <div>
  //       <Filter column={headerCl.column} table={table} />
  //     </div>
  //   );
  // };

  useEffect(() => {
    console.log('table?.resetRowSelection()', table)
    table?.resetRowSelection();
  }, [data, table])

  return (
    <>
      {/* <div>
        <input
          value={globalFilter ?? ''}
          onChange={e => setGlobalFilter(e.target.value)}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search all columns..."
        />
      </div> */}
      <CustomTable
        table={table}
        setSorting={setSorting}
        actionRefreshData={() => getData(clave, fInicio.current, true)}
        optionsTable={{ ofText: "Manifiestos de" }}
        isTableSelected={isTableSelected}
      // tableOptions={
      //   {
      //     styles : {
      //       width: '100%', height: '600px'
      //     },
      //     classNames : 'overflow-auto'
      //   }
      // }
      />
    </>
  );
};

export function Filter(props) {
  const { column, table } = { ...props };

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={columnFilterValue?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={columnFilterValue?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

export default ActManifiestosTable;
