import { useCallback, useMemo, useState } from "react";
import {
  ObtenerFrecuencias,
  EliminarFrecuencia
} from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";

function useCatFrecuencias() {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatFrecuencias = "Catálogo de Frecuencia de Visitas a Clientes";
  const idModalDelete = "staticBackdrop";
  const pathAltaFrecVis = "/suvalsa/catalogos/frecuencia-visitas/alta";
  const pathCatFrecVis = "/suvalsa/catalogos/frecuencia-visitas";

  const title = "Eliminar Visita";
  const pendingText = "Eliminando visita, por favor espere.";
  const successText = `Visita ${dataRowSelection[0]?.clave} eliminada correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "frecuencia",
        header: "Frecuencia",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Visitas de" };

  const GetFrecuencias= useCallback(async () =>{
    let rowsNew = [];
    console.log("obteniendo Datos de ws Frecuencias");
    const frec = await ObtenerFrecuencias();
    console.log("lista de Frecuencias: ", frec);

    if (frec) {
      rowsNew = frec.map((frecuencia) => ({
        clave: frecuencia.clave,
        id: frecuencia.id,
        frecuencia: frecuencia.frecuencia
      }));
    }
    return rowsNew;
  },[])

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarFrecuencia(id);
  }

  return {
    tituloCatFrecuencias,
    idModalDelete,
    pathAltaFrecVis,
    pathCatFrecVis,
    columnsData,
    opcionesTabla,
    GetFrecuencias,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
}

export default useCatFrecuencias;
