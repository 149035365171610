import { useMemo, useState } from "react";

async function GetManifestacionesEnv() {
  let rowsNew = [];
  console.log("obteniendo Datos de ws MAnifestaciones");
  const manif = []; //await ObtenerManifestaciones();
  console.log("lista de MAnifestaciones: ", manif);

  if (manif) {
    rowsNew = manif.map((res) => ({
      manifiesto: res.manifiesto,
      id: res.id,
      cliente: res.cliente,
      nombre: res.nombre,
      fVisita: res.fVisita,
      cantidad: res.cantidad
    }));
  }
  return rowsNew;
}

const useRecoleccionesEnviadas = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const tituloRecoleccionesEnv = "Resumen de las Recolecciones Enviadas";

  const optionsTable = { ofText: "Recolecciones de" };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "manifiesto",
        header: "Manifiesto",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "cliente",
        header: "Cliente",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "fVisita",
        header: "Fecha Visita",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "cantidad",
        header: "Cantidad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  async function GetRowsData() {
    let rowsNew = [];
    setLoading(true);
    console.log("obteniendo Datos de ws");
    rowsNew = await GetManifestacionesEnv();
    console.log("ROWS DATA: ", rowsNew);
    setData(rowsNew);
    setLoading(false);
    return rowsNew;
  }

  return {
    loading,
    tituloRecoleccionesEnv,
    optionsTable,
    columnsData,
    data,
    setData,
    GetRowsData
  };
};

export default useRecoleccionesEnviadas;
