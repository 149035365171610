import React, { useEffect, useMemo, useState } from "react";
import { Modal, initTE } from "tw-elements";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import useCatClientes from "../../Catalogos/Clientes/UseClientes";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { InputText } from "../../../componentes/Formik";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import { columnSize } from "../../../Constantes";

export default function AgregarClientesTable(props) {
  const [dataRowSelectionClientes, setDataRowSelectionClientes] = useState({});
  const [columnCustomFilters, setCustomColumnFilters] = useState("");

  const { GetClientes } = useCatClientes();

  const opcionesTablaClientes = { ofText: "Clientes de", multiSelect: true };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const {
    loading,
    loadingAction,
    //optionsTable,
    data: clientes,
    setData: setClientes,
    GetRowsData: GetRowsDataClientes,
    rowSelection,
    setRowSelection,
    getDataSelected
    //confirmarEliminacion,
    //buttonsGroup
  } = useCatalogo({
    //optionsTable: opcionesTablaClientes,
    getData: GetClientes,
    //idModalDelete,
    //pathNavTo: pathAltaResid,
    //pathNavBack: pathCatResid,
    //deleteData: actionDelete,
    dataRowSelection: dataRowSelectionClientes,
    setDataRowSelection: setDataRowSelectionClientes
    //notification
  });

  /**
   * Funcion encargada de enviar los datos de los nuevos clientes que se deben agregar a la ruta y actualiza la tabla de clientes en ruta
   * @param {*} datasSelected : es el estado de la seleccion en la tabla, contiene los indices seleccionados
   */
  async function SendNewData(datasSelected) {
    console.log(datasSelected);
    console.log(dataRowSelectionClientes);

    if (props.actionUpdateTable) {
      //se mandarian las nuevas filas para que se agreguen
      await props.actionUpdateTable(dataRowSelectionClientes);
    }
    //setRowSelection({});
  }

  function actionCancelar() {
    const myModal = new Modal(document.getElementById(props.idModalClientes));
    console.log(myModal);
    if (myModal) Modal.getOrCreateInstance(myModal).hide();
  }

  function actionAgregar() {
    SendNewData(rowSelection);
    actionCancelar();
  }
  
  const buttonsGroup = {
    buttonPrimary: {
      action: actionAgregar,
      disabled: !getDataSelected(),
      type: "button",
      label: "Agregar",
      modalDismiss: true
    },
    buttonSecondary: {
      action: actionCancelar,
      disabled: false,
      type: "button",
      label: "Cancelar",
      modalDismiss: true
    }
  };

  useEffect(() => {
    initTE({ Modal });
  }, []);

  //se actualiza el hook que controla la actualizaicon de los elementos en la tabla de clientes en base a los clientes existentes en la ruta
  useEffect(() => {
    if(props.clientesRuta && props.clientesRuta.length >0){
      clientes.forEach( (cl, index) => {
        //setRowSelection({1: true, 2: true, 3: true});
        const existeClienteRuta = props.clientesRuta.some(cr => cr.clave === cl.clave)
        //cuando encuentre el cliente registrado en la ruta, se obtiene el index para seleccionarlo en la tabla de clientes
        if(existeClienteRuta){
          setRowSelection((prevstate) => ({
            ...prevstate,
            [index]: true
        }))
        }
      })
    }
  },[clientes, props.clientesRuta])

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <span className="w-full flex text-left">
        Seleciona un cliente de la lista y posteriormente da clic en en botón
        AGREGAR para enviarlo a la lista de clientes por ruta.
      </span>

      <div className="flex flex-col w-full gap-2">
        <div className="w-full md:w-1/2">
          <InputText
            disabled={loading}
            label="Buscar por nombre"
            name="nombreSearch"
            type="text"
            placeholder=""
            onChange={async (e) => {
              setCustomColumnFilters([
                {
                  id: "nombre",
                  value: e.target.value
                }
              ]);
            }}
          />
        </div>
        <CustomGenericTable
          columnsData={columnsData}
          data={clientes}
          optionsTable={opcionesTablaClientes}
          setData={setClientes}
          getData={GetRowsDataClientes}
          columnCustomFilters={columnCustomFilters}
          isTableSelected={true}
          isMultiRowSelection={true}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          setDataRowSelection={setDataRowSelectionClientes}
        />
        <ButtonGroup {...buttonsGroup} />
      </div>
    </>
  );
}
