import { useCallback, useMemo, useState } from "react";
import { EliminarCliente, ObtenerClientes } from "../../../Apis/FetchClientes";
import { columnSize } from "../../../Constantes";

const useCatClientes = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatClientes = "Catálogo de Clientes";
  const idModalDelete = "staticBackdrop";
  const pathAltaCliente = "/suvalsa/datos/cliente/alta";
  const pathCatClientes = "/suvalsa/catalogos/clientes";

  const title = "Eliminar Cliente";
  const pendingText = "Eliminando cliente, por favor espere.";
  const successText = `Cliente ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };
  
  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "razonSocial",
        header: "Razón Social",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "tipoSociedad",
        header: "Tipo de Sociedad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "regimenFiscal",
        header: "Régimen Fiscal",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "usoCfdi",
        header: "Uso de CFDI",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "correoE",
        header: "Correo Electrónico",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "segundoCorreoE",
        header: "Segundo Correo E.",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ruta",
        header: "Ruta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "orden",
        header: "Orden",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "anioa",
        header: "Año Alta",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "frec",
        header: "Frec.",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "condicionPago",
        header: "Condición Pago",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "rfc",
        header: "RFC",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "revFacturas",
        header: "Revisión Facturas",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "pagoFacturas",
        header: "Pago de Facturas",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "regIne",
        header: "Registro INE",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "contratos",
        header: "Contratos",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "fechaInicio",
        header: "Inicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "fechaFin",
        header: "Terminación",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "noCons",
        header: "No.Cons",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "paqInsm",
        header: "Paq.Insm",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "refBancaria",
        header: "Ref. Bancaria",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "calle",
        header: "Calle",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "colonia",
        header: "Colonia",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "estado",
        header: "Estado",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "claveEstadoINE",
        header: "Clave Estado (INE)",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "telefono",
        header: "Teléfono",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "contacto",
        header: "Contacto",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Clientes de" };

  const GetClientes= useCallback(async()=> {
    let rowsNew = [];
    console.log("obteniendo Datos de ws Clientes");
    const clientes = await ObtenerClientes();
    console.log("lista de Clientes: ", clientes);

    if (clientes) {
      rowsNew = clientes.map((res) => ({
        clave: res.clave,
        id: res.id,
        familia: res.fam,
        nombre: res.nombre,
        razonSocial: res.fnombre,
        tipoSociedad: res.tiposociedad,
        regimenFiscal: res.regimenfiscal,
        usoCfdi: res.usocfdi,
        correoE: res.correoelectronico,
        segundoCorreoE: res.segundocorreoelectronico,
        ruta: res.ruta,
        orden: res.orden,
        anioa: res.aalta,
        frec: res.frec,
        condicionPago: res.condspago,
        rfc: res.rfc,
        revFacturas: res.revision,
        pagoFacturas: res.pago,
        status: res.status,
        regIne: res.registro,
        contratos: res.conts,
        fechaInicio: res.inicio,
        fechaFin: res.fin,
        noCons: res.cons,
        paqInsm: res.paqinsm,
        servicio: res.serv,
        refBancaria: res.refbanco,
        calle: res.calle,
        colonia: res.colonia,
        ciudad: res.ciudad,
        estado: res.estado,
        claveEstadoINE: res.edo, //REVISAR
        telefono: res.telefono,
        contacto: res.contacto
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarCliente(id);
  }

  return {
    tituloCatClientes,
    idModalDelete,
    pathAltaCliente,
    pathCatClientes,
    columnsData,
    opcionesTabla,
    GetClientes,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
};

export default useCatClientes;
