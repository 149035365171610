import React from 'react'

const ReportesAlmacen = () => {
  return (
    <div>
      ReportesAlmacen
    </div>
  )
}

export default ReportesAlmacen
