import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import useCatInsumos from "./UseInsumos";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoInsumos = () => {
  const {
    tituloCatInsumos,
    idModalDelete,
    pathAltaIns,
    pathCatIns,
    columnsData,
    opcionesTabla,
    dataRowSelection,
    setDataRowSelection,
    GetInsumos,
    actionDelete,
    notification
  } = useCatInsumos();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: insumos,
    setData: setInsumos,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetInsumos,
    deleteData: actionDelete,
    idModalDelete,
    pathNavTo: pathAltaIns,
    pathNavBack: pathCatIns,
    dataRowSelection,
    setDataRowSelection,
    notification
  });
  
  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}

      <CardNavigate title={tituloCatInsumos}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={insumos}
            setData={setInsumos}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            id={idModalDelete}
            title={"Eliminar insumo"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Insumo con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoInsumos;
