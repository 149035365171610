import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import AltaVehiculoForm from "./AltaVehiculo.Form";

const AltaVehiculo = () => {
  const tituloAVehiculo = "Alta de Vehículo";

  return (
    <CardNavigate title={tituloAVehiculo}>
      <AltaVehiculoForm />
    </CardNavigate>
  );
};

export default AltaVehiculo;
