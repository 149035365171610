import React from 'react'

const SaltarNumeracion = () => {
  return (
    <div>
      SaltarNumeracion
    </div>
  )
}

export default SaltarNumeracion
