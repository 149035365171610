import React from "react";
import { Form, Formik } from "formik";
import { InputText, Select as SelectOpt } from "../../../componentes/Formik";



// Valores iniciales del formulario
const initialValues = {
    claveRuta: "",
};

const ConsultaGastosForm = () => {
    // Función para manejar el envío del formulario
    const handleSubmit = (values) => {
        console.log(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="flex flex-col w-full p-2 gap-2">
                    <div className="flex flex-col gap-4">
                        <InputText
                            label="Clave Ruta"
                            name="claveRuta"
                            type="text"
                        />
                        <InputText
                            label="Fecha Inicio"
                            name="fechaInicio"
                            type="date"
                        />
                        <InputText
                            label="Clave Ruta"
                            name="claveRuta"
                            type="text"
                        />
                        <InputText
                            label="Fecha Inicio"
                            name="fechaInicio"
                            type="date"
                        />

                    </div>
                    <button type="submit" className="btn-secondary" disabled={isSubmitting}>
                        Consultar
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default ConsultaGastosForm;