import { useCallback, useMemo, useState } from "react";
import {
  ObtenerNumerosDatosCtrl,
  EliminarNumerosDatosCtrl
} from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";

const useCatDatosNumeros = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatNumerosDatosControl = "Catálogo de Números y Datos de Control";
  const idModalDelete = "staticBackdrop";
  const pathAltaDatosNum = "/suvalsa/catalogos/datos-y-numeros-control/alta";
  const pathCatDatosNum = "/suvalsa/catalogos/datos-y-numeros-control";

  const title = "Eliminar Números y Datos de Control";
  const pendingText = "Eliminando registro, por favor espere.";
  const successText = `Registro ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  // const formatCell = (cell, value) => {
  //   cell.classList.add("hidden");
  // };

  // const columnsData = [
  //   { label: "Clave", field: "clave", sort: true, width: 50, fixed: true },
  //   { label: "Concepto", field: "concepto", sort: true },
  //   { label: "Dato - Número - Código", field: "datonumerocodigo", sort: true },
  //   { label: "Status", field: "status", sort: true },
  //   { label: "Observaciones", field: "observaciones", sort: true },
  //   { label: "", field: "id", sort: false, width: 10, format: formatCell }
  // ];
  const columnsData = useMemo(() => 
    [
  {
    accessorKey: "id",
    header: "Id",
    enableColumnFilter: false
    //footer: props => props.column.id,
    //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
  },
  {
    accessorKey: "clave",
    header: "Clave",
    enableColumnFilter: true,
    filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
    //filterFn: 'customFilter', //using our custom function filter
    //filterFn: "fuseFilterFn"
    //footer: props => props.column.id,
    size: 50, //set column size for this column
    enableSorting: false
  },
  {
    accessorKey: "concepto",
    header: "Concepto",
    enableColumnFilter: false,
    size: columnSize, //set column size for this column
    enableSorting: false
    //footer: props => props.column.id,
  },
  {
    accessorKey: "datonumerocodigo",
    header: "Dato - Número - Código",
    enableColumnFilter: false,
    enableSorting: false
    //footer: props => props.column.id,
  },
  {
    accessorKey: "status",
    header: "Status",
    enableColumnFilter: false,
    enableSorting: false,
    //footer: props => props.column.id,
    size: 100 //set column size for this column
  },
  {
    accessorKey: "observaciones",
    header: "Observaciones",
    enableColumnFilter: false,
    enableSorting: false,
    size: 100 //set column size for this column
    //footer: props => props.column.id,
  }
],[]);

  const opcionesTabla = { ofText: "Datos y Números de" };
  //const optionsTable= { noFoundMessage: 'Datos no encontrados', ofText: 'Visitas de', loadingMessage: 'Cargando resultados', }

  const GetDatosNumeros= useCallback(async() =>{
    let rowsNew = [];
    const datosNumeros = await ObtenerNumerosDatosCtrl();
    //await ObtenerFrecuencias();
    console.log("lista de DatosNumeros: ", datosNumeros);

    if (datosNumeros) {
      rowsNew = datosNumeros.map((res) => ({
        clave: res.clave,
        id: res.id,
        concepto: res.concepto,
        datonumerocodigo: res.dato,
        status: res.status,
        observaciones: res.notas
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarNumerosDatosCtrl(id);
  }

  return {
    tituloCatNumerosDatosControl,
    idModalDelete,
    pathAltaDatosNum,
    pathCatDatosNum,
    columnsData,
    opcionesTabla,
    GetDatosNumeros,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
};

export default useCatDatosNumeros;
