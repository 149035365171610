import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaRutaPlanta = Yup.object({
  planta: Yup.string().required(MSJ_DATO_REQ),
  nombre: Yup.string().required(MSJ_DATO_REQ),
  rutaplanta: Yup.string().required(MSJ_DATO_REQ)
});

export default schemaAltaRutaPlanta;
