import React from 'react'

const ReimprimirCataPorta = () => {
  return (
    <div>
      ReimprimirCataPorta
    </div>
  )
}

export default ReimprimirCataPorta
