import { useEffect, useState } from "react";

import { createOption, sleep } from "../../utils/CommonUtils";
import { useExecutePromise } from "../../Hooks/UsePromiseAction";
import { CrearSalida, ObtenerMaxSalida } from "../../Apis/FetchSalidas";
import CustomToastify from "../../componentes/Toasts/CustomToast";
import dayjs from "dayjs";

// Hook principal para manejar la lógica de los manifiestos
const useAlmacenAlta = () => {
  // Definición de los estados necesarios

  const [loadInsumos, setLoadInsumos] = useState(false);
  const [loadClients, setLoadClients] = useState(false);
  const [dataRowsInsumos, setDataRowsInsumos] = useState({});
  const [dataRowsClients, setDataRowsClients] = useState({});

  // Definición de los IDs de los modales utilizados
  const idModalSelectClients = "modalSelClients";

  const tipos = {
    V: 'Venta',
    D: 'Devolución',
    P: 'Préstamo',
    O: 'Otro',
    C: 'Cancelada',
  }

  function addSelectTipo() {
    return [
      createOption('1v', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.V)], tipos.V),
      createOption('2d', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.D)], tipos.D),
      createOption('3p', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.P)], tipos.P),
      createOption('4o', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.O)], tipos.O),
      createOption('5c', Object.keys(tipos)[Object.values(tipos).indexOf(tipos.C)], tipos.C),
    ]
  }

  const { runAction, loadingAction } = useExecutePromise();
  const title = 'Alta de Paquete de Salidas del Almacén'
  const pendingText = 'Enviando Datos del paquete con Insumos, por favor espere.'
  const successText = "Alta de Paquete. Datos del paquete de Salidas con Insumos agregados correctamente."
  const errorText = "Ocurrió un error inesperado. Por favor reinténtalo más tarde."

  async function onSaveSal(values) {
    console.log(values)
    console.log('...Agregando para agregar los insumos a la salida de la ruta:', values.ruta)
    if( !validateDataSave() ){
      CustomToastify({
        title: "Valores incorrectos", message: "Por favor valida las cantidades de los insumos antes de guardar.", type: 'warning'
      })
      return false;
    }

    await runAction({
      promiseAction: async () => {
        const dataSalidaRuta = {
          ruta: values.ruta,
          fInicio: values.fInicio,
          tipo: values.tipo,
          //nombre: values.nombre,
          //nombreRuta: values.nombreRuta,
          referencia: values.referencia,
          //manif: values.manif,
        };
        //await ActualizarRutaCliente(datosActRuta);
        console.log("dataSalidaRuta:", dataSalidaRuta);
        await ProcesarClienteRuta(dataSalidaRuta);
      }, title, pendingText, successText, errorText
    })
  }

  useEffect(()=>{
    console.log(dataRowsInsumos)
  },[dataRowsInsumos])

  async function ProcesarClienteRuta(dataSalidaRuta) {
    for(let client of dataRowsClients) {
      const folio = await ObtenerMaxSalida();
      const fecha = dayjs(dataSalidaRuta.fInicio).format("YYYY-MM-DD");
      //aqui debe existir una condicion de que si no se obtiene el max de salida, no se agregue
      if (folio.maxNumero) {
        //"yyyy-MM-DD");
        const noSalida = Number(folio.maxNumero) + 1;
        for( let ins of dataRowsInsumos) {
          const cant = Number(ins.cantidad);
          //si se capturo un valor numerico y es mayor a 0
          if (!isNaN(cant) && cant > 0) {
            console.log(
              "se agregara la salida para ",
              ins.clave,
              "con folio ",
              noSalida
            );
            const bodyData = {
              numero: noSalida,
              fecha: fecha, //"2024-05-10",
              tipo: dataSalidaRuta.tipo,
              insumo: ins.id,
              cantidad: cant,
              unidad: ins.unidad,
              referencia: dataSalidaRuta.referencia,//values.ruta + client.familia + ins.clave,
              familia: client.familia,
              cliente: client.clave,
              manif: '',
              factura: "",
              usom: "",
              status: "1",
              ruta: dataSalidaRuta.ruta,
            };
            console.log(bodyData)
            await CrearSalida(bodyData);
            // console.log(ins.clave, {
            //   existen: ins.existencias - cant
            // })
            //await sleep(5000);
            // await ActualizarInsumo(ins.clave, {
            //   existen: ins.existencias - cant
            // });
          }
        }
        //TODO: proceso para actualizar tabla insumos en pantalla
      } else {
        console.log("no se recupero el max de salidas");
        return false;
      }
    }
  }

  function validateDataSave(){
    let validate = true;
    for (let ins of dataRowsInsumos){
      const cant = Number(ins.cantidad);
      //si se capturo un valor numerico y es mayor a 0
      console.log(
        "Validando cantidad de insumo ",
        ins.clave
      );
      console.log(validate);
      if (isNaN(cant) || (!isNaN(cant) && cant <= 0) ){
        validate= false;
      }
    }

    return validate;
  }

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    loadingAction,
    onSaveSal,
    addSelectTipo,
    loadClients,
    setLoadClients,
    loadInsumos,
    setLoadInsumos,
    dataRowsInsumos,
    dataRowsClients,
    setDataRowsClients,
    setDataRowsInsumos,
    idModalSelectClients
  };
};

export default useAlmacenAlta;