import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
// Initialization for ES Users
import { Modal, initTE, Select } from "tw-elements";

import { InputText, SelectFile } from '../../../../componentes/Formik';
import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import { ButtonGroup } from '../../../../componentes/Button/GenericButton';
import Accordion from '../../../../componentes/Accordion/Accordion';
import schemaAltaSalidaAlmacen from '../../Schemas/SchemaAltaSalidaAlmacen';
import useAlmacenAlta from '../../useAlmacen'
import useActualizarRuta from '../../../Rutas/Actualizar/UseActualizarRuta';
import CustomModal from '../../../../componentes/Modals/CustomModal';
import InsumosTable from '../../../Catalogos/Insumos/components/table/InsumosTable';
import ClientesRutaTable from '../../../Rutas/components/table/ClientesRutaTable';

export default function AltaSalidaAlmacenForm() {

  const [clave, setClave] = useState();
  const [flagExpanded, setFlagExpanded] = useState("false");

  const {
    loading,
    loadingData,
    rutaData,
    dataFounded,
    BuscarRuta,
  } = useActualizarRuta();

  const {
    loadingAction,
    onSaveSal,
    addSelectTipo,
    loadClients,
    setLoadClients,
    loadInsumos,
    setLoadInsumos,
    dataRowsInsumos,
    dataRowsClients,
    setDataRowsClients,
    setDataRowsInsumos,
    idModalSelectClients
  } = useAlmacenAlta();

  const initValuesAdd = {
    numero: "12345",
    fInicio: "",
    tipo: "",
    ruta: "",
    nombreRuta: "",
    referencia: "",
  };

  const onChangeRuta = async (clave, setFieldValue) => {
    console.log("onchange Clave: ", clave);
    const data = await BuscarRuta(clave)// BuscarRutaPorFecha(clave, fInicio.current);
    console.log("data::::", data);
    setFieldValue("nombreRuta", data.nombre);
    setFieldValue("ruta", clave);
    setFieldValue("referencia", "");
    setFieldValue("tipo", "");
    setFieldValue("referencia", "");
    //si se captura una clave y la consulta de la ruta si regresa info, se expande el acordion, si no, se oculta
    if (clave !== "" && data.id !== "") {
      setLoadInsumos(true)
      setFlagExpanded("true")
    }
    else {
      setLoadInsumos(false)
      setFlagExpanded("false")
    }
  };

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      disabled:
        loading ||
        dataRowsInsumos.length <= 0,
      type: "submit",
      label: "Guardar"
    },
    buttonSecondary: {
      action: actionShowClientes,
      disabled: loading || !dataFounded,
      type: "button",
      label: "Seleccionar Clientes"
    },
  };

  function actionShowClientes() {
    const myModal = new Modal(document.getElementById(idModalSelectClients));
    //se activa el flag para cargar los clientes al momento de que se muestre la tabla
    setLoadClients(true);
    myModal.show();
  }

  async function onSubmitSave(values) {
    if (dataRowsClients.length <= 0) {
      actionShowClientes();
      return
    }
    await onSaveSal(values);
  }

  useEffect(() => {

    const selectTipo = document.querySelector("#selectTipo");
    if (selectTipo) Select.getOrCreateInstance(selectTipo);

    const myModal = document.getElementById(idModalSelectClients);
    initTE({ Modal });
    myModal?.addEventListener("hide.te.modal", () => {
      //para controlar la recarga cada que se oculta o muestra el modal
      setLoadClients(false);
    });
  }, []);

  return (
    <>
      {(loadingData || loadingAction) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAdd}
        validationSchema={schemaAltaSalidaAlmacen}
        onSubmit={onSubmitSave}
      >
        {(props) => {
          const { isSubmitting, setFieldValue } = props;

          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos para la salida"
                idTarget="collapseAltaSalidasVForm"
                expanded="true"
              >
                <div className=" grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 col-start-2 items-start gap-2 w-full">
                  {/* <InputText
                    disabled
                    label="Numero"
                    name="numero"
                    type="number"
                    placeholder=""
                  /> */}
                  <InputText
                    disabled={isSubmitting || loadingData}
                    value={clave}
                    label="R+No. de la Ruta"
                    name="ruta"
                    type="number"
                    placeholder=""
                    onBlur={async (e) => { await onChangeRuta(e.target.value, setFieldValue); }}
                    onKeyDown={async (e) => {
                      //presiona enter o tab
                      if (e.keyCode === 13 || e.keyCode === 9) {
                        await onChangeRuta(e.target.value,
                          setFieldValue);
                      }
                    }}
                    onChange={async (e) => setClave(e.target.value) }
                  />
                  <InputText
                    disabled={loadingData}
                    label="Fecha de Inicio"
                    type="date"
                    placeholder=""
                    name="fInicio"
                  />
                  <SelectFile
                    id="selectTipo"
                    disabled={isSubmitting || loadingData}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Tipo"
                    name="tipo"
                    validateOnChange
                    onChangeAction={async (e) => {
                      console.log(e.target.value)
                    }}
                  >
                    <option hidden defaultValue value=""></option>
                    {addSelectTipo()}
                  </SelectFile>
                  <InputText
                    disabled={true}
                    label="Nombre"
                    name="nombreRuta"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={false}
                    label="Referencia"
                    name="referencia"
                    type="text"
                    placeholder=""
                  />

                  {/* <button
                    disabled={isSubmitting || !(clave && clave !== '')}
                    type="button"
                    className="btn-secondary m-3"
                    onClick={async () => {
                      await onChangeRuta(clave, setFieldValue);
                    }}
                  >
                    Buscar
                  </button> */}
                </div>
              </Accordion>
              <Accordion
                titulo={"Lista de Insumos"}
                idTarget="collapseInsumos"
                expanded={flagExpanded}
                disabled={!dataFounded}
              >
                {
                  flagExpanded === "true" ? (
                    <InsumosTable isEditable={true} isSelected={true} multiSelected={true} setDataRowSelection={setDataRowsInsumos} />
                  ):<></>
                }
              </Accordion>
              <CustomModal
                id={idModalSelectClients}
                title={`Agregar Clientes - Ruta ${rutaData?.clave ?? ""}`}
                withForm
              >
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-col w-full gap-2">
                    <ClientesRutaTable
                      clave={clave}
                      autoLoad={loadClients}
                      isSelected={true}
                      multiSelected={true}
                      setDataRowSelection={setDataRowsClients} />
                  </div>
                  <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                    <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
                      {/* <span data-twe-modal-dismiss>
            </span> */}
                      <button
                        type="button"
                        className="btn-secondary"
                        data-te-modal-dismiss
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </CustomModal>
              <div className="h-2" />
              <ButtonGroup
                buttonPrimary={buttonsGroup.buttonPrimary}
                buttonSecondary={buttonsGroup.buttonSecondary}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
