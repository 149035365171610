// Importación de librerías y componentes necesarios
import { useEffect, useRef, useState } from "react";
import { Modal, initTE } from "tw-elements";
import {
  ObtenerClientesManifiestos,
  ObtenerClientesManifiestosPorFecha,
  ObtenerClientesManifiestosPorFechaNative
} from "../../../Apis/FetchManifiestos";
import { BuscarRutaClave } from "../../../Apis/FetchRutas";
import { ObtenerAgentes, ObtenerVehiculos } from "../../../Apis/FetchCatalogos";
import dayjs from "dayjs";
//import { ObtenerSalidasManif } from "../../../Apis/FetchSalidas";
import { BuscarClientePorClave } from "../../../Apis/FetchClientes";
import AltaManifiestosPdf from "../../../componentes/PDFServices/AltaManifiestosPdf";
import { pdf } from '@react-pdf/renderer';

async function generarReporteConClaveYFecha(ruta, fecha) {
  console.log('Generando reporte para la clave:', ruta, 'y fecha:', fecha);

  try {
    // Consultar los manifiestos con la clave y fecha proporcionadas
    const manifiestos = await ObtenerClientesManifiestosPorFechaNative(ruta, fecha);

    if (manifiestos.length === 0) {
      console.log('No se encontraron manifiestos para la clave y fecha proporcionadas.');
      return;
    }

    // Aquí puedes agregar la lógica para generar y descargar el reporte basado en los manifiestos
    console.log('Manifiestos obtenidos:', manifiestos);

    // Generar el PDF utilizando el componente AltaManifiestosPdf
    const pdfInstance = pdf(<AltaManifiestosPdf datos={manifiestos} />);

    // Crear el blob del documento PDF
    const blob = await pdfInstance.toBlob();

    // Descargar el archivo PDF generado
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.pdf';
    link.click();

    console.log('Reporte generado exitosamente en output.pdf');

  } catch (error) {
    console.error('Error al generar el reporte:', error);
  }
}



async function obtenerDatosCliente(clave) {
  try {
    const cliente = await BuscarClientePorClave(clave);
    console.log(cliente);
    return cliente;
  } catch (error) {
    console.log("Error al obtener datos del cliente: ", error);
    return null;
  }
}

// Función para calcular el día de visita basado en la fecha de inicio y fecha de visitaimport { ObtenerSalidasManif } from "../../../Apis/FetchSalidas";

function obtenerDiaVisita(fInicio, fVisita) {
  let diaVisita = "";
  try {
    if (!fInicio || !fVisita) return "";

    const dateIni = dayjs(fInicio);
    const dateVis = dayjs(fVisita);
    diaVisita = dateVis.diff(dateIni, "day") + 1;
  } catch (error) {
    console.log(error);
  }

  return diaVisita;
}

// Función para cargar los manifiestos de clientes según la clave y fecha proporcionadas
export async function loadClientesManif(clave, fecha, isUpdate = false) {
  let cManif = [];
  // Si se proporcionan clave y fecha, se intenta obtener los manifiestos según estos datos
  if (clave !== "" && fecha !== "") {
    cManif = await ObtenerClientesManifiestosPorFecha(clave, fecha);
  }
  // Si no se encontraron manifiestos según la fecha, se obtiene solo por clave
  if (!isUpdate) {
    console.log("lista de Clientes cManif: ", cManif);
    const clientesManif = await ObtenerClientesManifiestos(clave);
    const auxManifest = cManif.length > 0 ? clientesManif.map(clManif => {
      const manifFecha = cManif.find(cf => cf.cliente === clManif.cliente);
      console.log(manifFecha)
      if (manifFecha) {
        return manifFecha
      } else return clManif
    }) : clientesManif;

    console.log("lista de Clientes auxManifest: ", auxManifest);
    cManif = auxManifest
  }

  // if (cManif.length === 0 && !isUpdate) {
  //   cManif = await ObtenerClientesManifiestos(clave);
  // }

  let rowsNew = [];
  console.log("lista de Clientes Manifiestis: ", cManif);

  // Mapeo de los datos recibidos para crear una estructura adecuada
  if (cManif && cManif.length) {
    rowsNew = await Promise.all(
      cManif.map(async (res) => {
        const clienteData = await obtenerDatosCliente(res.cliente);
        return {
          id: res.id,
          clave: res.cliente,
          ciudad: res.ciudad,
          familia: res.fam,
          nombre: clienteData?.nombre ?? res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.serv,
          dia: res.dia ?? obtenerDiaVisita(fecha, res.fvisita),
          fvisita: res.fvisita,
          salida: res.salida,
          manifiesto: res.salida ? res.clave : "", // Se obtiene de la consulta por clave y fecha
          planta: res.planta,
          rutaplanta: res.rutaplanta ? res.rutaplanta : `${clienteData?.ciudad ?? ""} / ${clienteData?.estado ?? ""}`
        };
      })
    );
  }
  console.log(rowsNew);
  return rowsNew;
}

// Función para reestablecer la fecha de visita basada en la fecha de inicio y día proporcionado
function reeestablecerFechaVisita(fInicio, dia) {
  console.log(fInicio, dia);
  if (dia === "") return "";

  const date = dayjs(fInicio);
  let days = Number(dia);
  if (isNaN(days)) return "";

  return date.add(days - 1, "days").format("YYYY-MM-DD");
}

// Función para recargar los manifiestos de clientes con nuevas fechas de visita
export async function reloadClientesManif(data, fInicio) {
  let rowsNew = [];
  console.log("lista de Clientes Manifiestis: ", data);

  if (data) {
    rowsNew = data.map((res) => {
      const fechaVisita = reeestablecerFechaVisita(fInicio, res.dia);
      return {
        id: res.id,
        clave: res.clave,
        ciudad: res.ciudad,
        familia: res.familia,
        nombre: res.nombre,
        ruta: res.ruta,
        orden: res.orden,
        servicio: res.servicio,
        dia: res.dia ?? obtenerDiaVisita(fInicio, fechaVisita),
        fvisita: fechaVisita,
        salida: res.salida,
        manifiesto: res.manifiesto,
        planta: res.planta,
        rutaplanta: res.rutaplanta
      };
    });
  }
  console.log(rowsNew);
  return rowsNew;
}

// Hook principal para manejar la lógica de los manifiestos
const useManifiestos = () => {
  // Definición de los estados necesarios para el manejo de los manifiestos
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [dataRowSelected, setDataRowSelected] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  // Estado inicial para la ruta
  const [rutaData, setRutaData] = useState([
    {
      clave: "",
      nombre: "",
      duracion: "",
      oper: "",
      aux: "",
      vehiculo1: "",
      id: ""
    }
  ]);

  // Definición de los IDs de los modales utilizados
  const idModalAgregarInsumo = "modalAgregarInsumo";
  const idModalSalidas = "modalSalidas";

  // Función para manejar el envío del formulario de alta de manifiestos
  async function onSubmitAltaManifestos(values) {
    console.log("onsubmit....");
    console.log("action alta");
    // Validación de que todas las fechas de visita han sido capturadas
    if (data.some((m) => m.fvisita === "")) {
      console.log(
        "Favor de completar todas las fechas de visita antes de guardar"
      );
    } else {
      // Validación de que los días agregados estén dentro del rango establecido
      data.every((clm) => {
        const inputDia = clm.dia === "" ? 0 : Number(clm.dia);
        console.log("dia capturado:", inputDia);
        if (inputDia > values.dias) {
          return false;
        } else {
          // Creación del manifiesto por cada registro
          return true;
        }
      });
    }
  }

  // Función para obtener los datos de manifiestos según la clave y fecha
  async function getDataFunction(clave, fecha, isUpdate = false) {
    setLoadingData(true);
    setData(await loadClientesManif(clave, fecha, isUpdate));
    setLoadingData(false);
  }

  // Función para buscar la información de una ruta específica
  async function BuscarRuta(clave) {
    console.log("consultando datos de la ruta:", clave);
    setLoadingData(true);
    let cRuta = [
      {
        clave: clave,
        nombre: "",
        duracion: "",
        oper: "",
        operador: "",
        aux: "",
        auxiliar: "",
        noEco: "",
        vehiculo: "",
        id: ""
      }
    ];
    if (clave && clave !== "") {
      const ruta = clave ? await BuscarRutaClave(clave) : null;
      console.log(ruta);

      if (ruta !== null && ruta.length > 0) {
        cRuta = ruta.map((r) => {
          const auxRuta = {
            clave: r.clave,
            nombre: r.nombre,
            duracion: r.duracion,
            oper: r.operador,
            operador: buscarAgente("OPE", r.operador),
            aux: r.auxiliar,
            auxiliar: buscarAgente("AUX", r.auxiliar),
            noEco: r.vehiculo,
            vehiculo: buscarVehiculo(r.vehiculo),
            id: r.id
          };
          return auxRuta;
        });
      }
    }
    console.log(cRuta);
    setRutaData(...cRuta);
    setLoadingData(false);
    return cRuta[0];
  }

  // Función para buscar un agente según su puesto y clave
  const buscarAgente = (puesto, clave) => {
    const selected = agentes
      ? agentes.find((op) => (op.puesto = puesto && op.clave === clave))
      : undefined;
    return selected?.nombre ?? "";
  };

  // Función para buscar un vehículo según su clave económica
  const buscarVehiculo = (claveEco) => {
    if (!claveEco) return "";

    const veh = vehiculos
      ? vehiculos.find((op) => op.CLAVE.trim() === claveEco.trim())
      : undefined;

    if (veh) return veh.MARCA + " " + veh.MODELO;
    else return "";
  };

  // Función para validar si se puede habilitar el reporte
  function isReporteEnabled() {
    let flagEnabled = true;
    const dataAux = data.find((manif) => manif.dia === "" || manif.dia < 0);
    if (dataAux) {
      flagEnabled = false;
    }
    return flagEnabled;
  }

  // Función para agregar una salida al manifiesto
  async function addSalida(noSalida, noManif) {
    setLoadingData(true);
    let rowsNew = [];

    if (data) {
      rowsNew = data.map((res, index) => {
        return {
          id: res.id,
          clave: res.clave,
          ciudad: res.ciudad,
          familia: res.familia,
          nombre: res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.servicio,
          dia: res.dia,
          fvisita: res.fvisita,
          salida: dataRowSelected.index === index ? noSalida : res.salida,
          manifiesto:
            dataRowSelected.index === index ? noManif : res.manifiesto,
          planta: res.planta,
          rutaplanta: res.rutaplanta
        };
      });
    }
    setData(rowsNew);
    setLoadingData(false);
    return rowsNew;
  }

  // Función para mostrar el modal de salidas
  function consultarSalidas() {
    console.log("action click Consultar:", idModalSalidas);
    const myModal = new Modal(document.getElementById(idModalSalidas));
    myModal.show();
  }

  // Función para mostrar el modal de agregar insumo
  function actionElaborar() {
    console.log("action click Elaborar:", idModalAgregarInsumo);
    const myModal = new Modal(document.getElementById(idModalAgregarInsumo));
    myModal.show();
  }

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      action: actionElaborar,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida ||
        !dataRowSelected?.fvisita ||
        (!dataRowSelected?.manifiesto &&
          !(
            dataRowSelected?.servicio === "CB" ||
            dataRowSelected?.servicio === "CI" ||
            dataRowSelected?.servicio === "TI")
        ),
      type: "button",
      label: "Elaborar"
    },
    buttonSecondary: {
      action: consultarSalidas,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida === "",
      type: "button",
      label: "Consultar"
    },
    getButtonTertiary: (ruta, fechaInicio) => ({
      action: async () => {
        if (!ruta || !fechaInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar el reporte.');
          return;
        }
        await generarReporteConClaveYFecha(ruta, fechaInicio);
      },
      disabled: loadingData || !(data.length > 0 && isReporteEnabled()),
      type: "button",
      label: "Generar Reporte"
    })
  };

  // Hook para inicializar y listar agentes y vehículos al cargar el componente
  useEffect(() => {
    initTE({ Modal });

    async function listarVehiculos() {
      setLoadingData(true);
      const listVehic = await ObtenerVehiculos();
      setVehiculos(listVehic);
      setLoadingData(false);
    }

    async function listarAgentes() {
      setLoadingData(true);
      const listaAgent = await ObtenerAgentes();
      setAgentes(listaAgent);
      setLoadingData(false);
    }

    // Consultar ambos catálogos (agentes y vehículos) al mismo tiempo
    const consultarTodo = async () => {
      const inicio = performance.now();
      await Promise.all([listarAgentes(), listarVehiculos()]);
      const fin = performance.now();
      console.log(fin - inicio);
    };

    consultarTodo();
  }, []);

  // Hook para actualizar la fila seleccionada cuando cambie la selección de filas
  useEffect(() => {
    if (data && data.length >= 0) {
      const indexKey = Object.keys(rowSelection);
      if (indexKey >= 0) {
        setDataRowSelected({
          ...data[Number(indexKey)],
          index: Number(indexKey)
        });
      }
    }
  }, [rowSelection, data]);

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    getDataFunction,
    BuscarRuta,
    loadingData,
    rutaData,
    data,
    rowSelection,
    setRowSelection,
    dataRowSelected,
    setData,
    idModalAgregarInsumo,
    idModalSalidas,
    addSalida,
    onSubmitAltaManifestos,
    buttonsGroup
  };
};

export const useActualizarManifiestos = () => {
  let dataSalidas = useRef([]);
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [dataRowSelected, setDataRowSelected] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  // const [agentes, setAgentes] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  const [, setRutaData] = useState([
    {
      clave: "",
      nombre: "",
      duracion: "",
      oper: "",
      aux: "",
      vehiculo1: "",
      id: ""
    }
  ]);

  const idModalAgregarInsumo = "modalAgregarInsumo";
  const idModalSalidas = "modalSalidas";

  async function onSubmitActManifestos(values) {
    console.log("action actualizar");
    //se valida que se cuente con todas las fechas de visita ya capturadas
    if (data.some((m) => m.fvisita === "")) {
      console.log(
        "Favor de completar todas las fechas de visita antes de guardar"
      );
    } else {
      data.every((clm) => {
        const inputDia = clm.dia === "" ? 0 : Number(clm.dia);
        //se valida si el dia agregado esta dentro de los dias establecidos en el manifiesto
        console.log("dia capturado:", inputDia);
        if (inputDia > values.dias) {
          return false;
        } else {
          //aqui se crea el manifiesto por cada registro
          return true;
        }
      });
    }
  }

  async function getDataFunction(clave, fecha, isUpdate = false) {
    setLoadingData(true);
    setData(await loadClientesManif(clave, fecha, isUpdate));
    setLoadingData(false);
  }

  async function BuscarRuta(clave) {
    console.log("consultando datos de la ruta:", clave);
    setLoadingData(true);
    let cRuta = [
      {
        clave: clave,
        nombre: "",
        duracion: "",
        oper: "",
        operador: "",
        aux: "",
        auxiliar: "",
        noEco: "",
        vehiculo: "",
        id: ""
      }
    ];
    if (clave && clave !== "") {
      const ruta = clave ? await BuscarRutaClave(clave) : null;
      console.log(ruta);

      if (ruta !== null && ruta.length > 0) {
        cRuta = ruta.map((r) => {
          const auxRuta = {
            clave: r.clave,
            nombre: r.nombre,
            duracion: r.duracion,
            oper: r.operador,
            //operador: buscarAgente("OPE", r.operador),
            aux: r.auxiliar,
            //auxiliar: buscarAgente("AUX", r.auxiliar),
            noEco: r.vehiculo,
            vehiculo: buscarVehiculo(r.vehiculo),
            id: r.id
          };
          return auxRuta;
        });
      }
    }
    console.log(cRuta);
    setRutaData(...cRuta);
    setLoadingData(false);
    return cRuta[0];
  }

  // const GetDataSalidas = useCallback(async () => {
  //   setLoadingData(true)
  //   const data = await ObtenerSalidasManif(dataRowSelected?.salida, dataRowSelected?.manifiesto );
  //   let cInsumos = [];
  //   if (data !== null && data.length > 0) {
  //     cInsumos = data.map((res) => {
  //       const insumo = {
  //         id: res.salida.id,
  //         clave: res.salida.insumo,
  //         insumo: res.modelo,
  //         cantidad: res.salida.cantidad,
  //         //tipo: res.tipo,
  //         unidad: res.salida.unidad,
  //       };
  //       return insumo;
  //     });
  //   }
  //   console.log(cInsumos)
  //   dataSalidas.current = cInsumos;
  //   setLoadingData(false)
  // },[dataRowSelected?.manifiesto, dataRowSelected?.salida, setLoadingData])


  // const buscarAgente = (puesto, clave) => {
  //   const selected = agentes
  //     ? agentes.find((op) => (op.puesto = puesto && op.clave === clave))
  //     : undefined;
  //   return selected?.nombre ?? "";
  // };

  // Función para buscar un vehículo según su clave económica
  const buscarVehiculo = (claveEco) => {
    if (!claveEco) return "";

    const veh = vehiculos
      ? vehiculos.find((op) => op.CLAVE.trim() === claveEco.trim())
      : undefined;

    if (veh) return veh.MARCA + " " + veh.MODELO;
    else return "";
  };

  // Función para validar si se puede habilitar el reporte
  function isReporteEnabled() {
    let flagEnabled = true;
    const dataAux = data.find((manif) => manif.dia === "" || manif.dia < 0);
    if (dataAux) {
      flagEnabled = false;
    }
    return flagEnabled;
  }

  // Función para agregar una salida al manifiesto
  async function addSalida(noSalida, noManif) {
    setLoadingData(true);
    let rowsNew = [];

    if (data) {
      rowsNew = data.map((res, index) => {
        return {
          id: res.id,
          clave: res.clave,
          ciudad: res.ciudad,
          familia: res.familia,
          nombre: res.nombre,
          ruta: res.ruta,
          orden: res.orden,
          servicio: res.servicio,
          dia: res.dia,
          fvisita: res.fvisita,
          salida: dataRowSelected.index === index ? noSalida : res.salida,
          manifiesto:
            dataRowSelected.index === index ? noManif : res.manifiesto,
          planta: res.planta,
          rutaplanta: res.rutaplanta
        };
      });
    }
    setData(rowsNew);
    setLoadingData(false);
    return rowsNew;
  }

  // Función para mostrar el modal de salidas
  function consultarSalidas() {
    const myModal = new Modal(document.getElementById(idModalSalidas));
    myModal.show();
  }

  // Función para mostrar el modal de agregar insumo
  function actionElaborar() {
    const myModal = new Modal(document.getElementById(idModalAgregarInsumo));
    myModal.show();
  }

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      action: actionElaborar,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida ||
        !dataRowSelected?.fvisita ||
        (!dataRowSelected?.manifiesto &&
          !(
            dataRowSelected?.servicio === "CB" ||
            dataRowSelected?.servicio === "CI" ||
            dataRowSelected?.servicio === "TI")
        ),
      type: "button",
      label: "Elaborar"
    },
    buttonSecondary: {
      action: consultarSalidas,
      disabled:
        loadingData ||
        Object.keys(rowSelection).length <= 0 ||
        dataRowSelected?.salida === "",
      type: "button",
      label: "Consultar"
    },
    buttonTertiary: (ruta, fechaInicio) => ({
      action: async () => {
        if (!ruta || !fechaInicio) {
          console.error('Por favor, proporciona una clave de ruta y una fecha de inicio para generar el reporte.');
          return;
        }
        await generarReporteConClaveYFecha(ruta, fechaInicio);
      },
      disabled: loadingData || !(data.length > 0 && isReporteEnabled()),
      type: "button",
      label: "Generar Reporte"
    })
  };

  // Hook para inicializar y listar agentes y vehículos al cargar el componente
  useEffect(() => {
    initTE({ Modal });

    async function listarVehiculos() {
      setLoadingData(true);
      const listVehic = await ObtenerVehiculos();
      setVehiculos(listVehic);
      setLoadingData(false);
    }

    // async function listarAgentes() {
    //   setLoadingData(true);
    //   const listaAgent = await ObtenerAgentes();
    //   setAgentes(listaAgent);
    //   setLoadingData(false);
    // }

    // Consultar ambos catálogos (agentes y vehículos) al mismo tiempo
    const consultarTodo = async () => {
      const inicio = performance.now();
      //await Promise.all([listarAgentes(), listarVehiculos()]);
      await listarVehiculos();
      const fin = performance.now();
      console.log(fin - inicio);
    };

    consultarTodo();
  }, []);

  // Hook para actualizar la fila seleccionada cuando cambie la selección de filas
  useEffect(() => {
    if (data && data.length >= 0) {
      const indexKey = Object.keys(rowSelection);
      if (indexKey >= 0) {
        setDataRowSelected({
          ...data[Number(indexKey)],
          index: Number(indexKey)
        });
      }
    }
  }, [rowSelection, data]);

  // useEffect(() => {
  //   if((dataRowSelected?.salida && dataRowSelected?.manifiesto) && dataRowSelected?.salida !== ""){
  //     GetDataSalidas();
  //   }else dataSalidas.current= [];

  // }, [dataRowSelected, dataRowSelected?.salida, dataRowSelected?.manifiesto, GetDataSalidas]);

  // Retorno del hook con todas las funciones y estados necesarios
  return {
    actionElaborar,
    getDataFunction,
    BuscarRuta,
    loadingData,
    data,
    dataSalidas,
    rowSelection,
    setRowSelection,
    dataRowSelected,
    setData,
    idModalAgregarInsumo,
    idModalSalidas,
    addSalida,
    onSubmitActManifestos,
    buttonsGroup
  };
};

export default useManifiestos;