import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaResiduo = Yup.object({
  clave: Yup.string()
    .required(MSJ_DATO_REQ),
  nombre: Yup.string()
  .required(MSJ_DATO_REQ),
  uni: Yup.string()
  .required(MSJ_DATO_REQ),
  tipo: Yup.string()
  .required(MSJ_DATO_REQ),
  titulo: Yup.string()
  .required(MSJ_DATO_REQ),
  clasificacion: Yup.string()
  .required(MSJ_DATO_REQ),
  clvine: Yup.string()
  .required(MSJ_DATO_REQ),
  sistemadfinal: Yup.string()
  .required(MSJ_DATO_REQ),
  empresadfinal: Yup.string()
  .required(MSJ_DATO_REQ),
  autorizacion: Yup.string()
  .required(MSJ_DATO_REQ),
  codigo: Yup.string()
  .required(MSJ_DATO_REQ),
});

export default schemaAltaResiduo;
