import { useCallback, useEffect, useState } from "react";
import { Select } from "tw-elements";
//import { InputText, Select as SelectOpt } from "../componentes/Formik";
import { ObtenerAgentes } from "../Apis/FetchCatalogos";
import CustomOption from '../componentes/utils/Option'

const UseAgentesSelect = () => {
    //const disabled = false;
    const idAgenteOper = "selectOper"
    const idAgenteAux = "selectAux"
    const [gettingData, setGettingData] = useState(false);
    const [agentes, setAgentes] = useState([]);
    const [oper, setOper] = useState("");
    const [aux, setAux] = useState("");
    const operador = { value: oper, label: "Operador", name: "oper" };
    const auxiliar = { value:aux, label: "Auxiliar", name: "aux"  };

    const agregarSelectAg = (agentes, puesto) => {
        if (agentes === null) {
            return "";
        }
        const ags = agentes.filter((ags) => ags.puesto === puesto);

        return ags.map((ag) => (
            CustomOption(ag.id, ag.clave, ag.clave)
        )
        );
    };

    const agregarSelectAgDefault = (puesto, defaultValue) => {
        if (agentes === null) {
          return "";
        }
        const ags = agentes.filter((ags) => ags.PUESTO === puesto);
        console.log(ags.length);
    
        let currentAg = [];
        if (defaultValue) {
          const agsCurrent = agentes.filter(
            (ags) => ags.puesto === puesto && ags.clave === defaultValue
          );
          console.log("puesto:", puesto);
          console.log("agsCurrent:", agsCurrent);
          if (agsCurrent.length === 0)
            currentAg.push(CustomOption(defaultValue, defaultValue, defaultValue));
          console.log("currentAg", currentAg);
        }
    
        ags.forEach((ag) =>
          currentAg.push(CustomOption(ag.id, ag.clave, ag.clave))
        );
    
        console.log("currentAg", puesto, currentAg);
        return currentAg;
    };

    const buscarAgente = (puesto, clave) => {
    const selected = agentes
      ? agentes.find((op) => (op.puesto = puesto && op.clave === clave))
      : undefined;
      if(puesto === 'OPE')
        setOper(selected?.nombre ?? "")
        else setAux(selected?.nombre ?? "")
    return selected?.nombre ?? "";
  };

    const onChangeOper = useCallback((e) => {
        console.log("e.target: ", e.target);
        const valueSel = e.target.value.trim();
        const selected = agentes.find((op) => op.clave === valueSel);
        console.log(selected);
        if (selected) setOper(selected.nombre);
    },[agentes]);

    const onChangeAux = useCallback((e) => {
        console.log("e.target: ", e.target);
        const valueSelAux = e.target.value.trim();
        const selected = agentes.find((op) => op.clave === valueSelAux);
        console.log(selected);
        if (selected) setAux(selected.nombre);
    },[agentes]);

    useEffect(() => {
        console.log("agente: ", agentes);
        if (agentes !== null && agentes.length > 0) {
            console.log("setting onchange Select/Oper");
            const selectAgenteOper = document.querySelector(`#${idAgenteOper}`);
            const selectAgenteAux = document.querySelector(`#${idAgenteAux}`)
            if (selectAgenteOper) {
                selectAgenteOper.addEventListener("valueChange.te.select", (e) => {
                    onChangeOper(e);
                });
            }
            if (selectAgenteAux) {
                selectAgenteAux.addEventListener("valueChange.te.select", (e) => {
                    onChangeAux(e);
                });
            }

        } else {
            async function listarAgentes() {
                setGettingData(true);
                console.log("Onteniendo lista de Agentes::: ");
                const listaAgent = await ObtenerAgentes();
                console.log("listaAgent ", listaAgent);
                setAgentes(listaAgent);
                setGettingData(false);
            }

            if (agentes !== null && agentes.length === 0) listarAgentes();
        }
    }, [agentes, onChangeAux, onChangeOper]);

    useEffect(() => {
        const selectOper = document.querySelector(`#${idAgenteOper}`);
        if (selectOper) Select.getOrCreateInstance(selectOper);
        const selectAux = document.querySelector(`#${idAgenteAux}`);
        if (selectAux) Select.getOrCreateInstance(selectAux);
    }, [onChangeAux, onChangeOper]);

    // function AgenteOperOption (){
    //     return (
    //         <>
    //             <SelectOpt
    //                 id={idAgenteOper}
    //                 disabled={disabled || gettingData}
    //                 className="pt-[0.1235rem]"
    //                 data-te-select-init
    //                 label={operador.label}
    //                 name={operador.name}
    //             >
    //                 <option hidden defaultValue value=""></option>
    //                 {agregarSelectAg(agentes, "OPE")}
    //             </SelectOpt>

    //             <InputText
    //                 value={oper}
    //                 disabled={true}
    //                 label=""
    //                 name="operador"
    //                 type="text"
    //                 placeholder=""
    //             />
    //         </>
    //     )

    // }

    // const AgenteAuxOption = () => {
    //     return (
    //         <>
    //             <SelectOpt
    //                 id={idAgenteAux}
    //                 disabled={disabled || gettingData}
    //                 className="pt-[0.1235rem]"
    //                 data-te-select-init
    //                 label={auxiliar.label}
    //                 name={auxiliar.name}
    //             >
    //                 <option hidden defaultValue value=""></option>
    //                 {agregarSelectAg(agentes, "AUX")}
    //             </SelectOpt>
    //             <InputText
    //                 value={aux}
    //                 disabled={true}
    //                 label=""
    //                 name="auxiliar"
    //                 type="text"
    //                 placeholder=""
    //             />
    //         </>
    //     )

    // }

    return {
        gettingData, agentes, buscarAgente,
        agregarSelectAg,agregarSelectAgDefault,
        idAgenteOper, operador,
        idAgenteAux, auxiliar, 
    }
}

export default UseAgentesSelect;