import { useCallback, useMemo, useState } from "react";
import {
  ObtenerVehiculos,
  EliminarVehiculo
} from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";

const useCatVehiculos = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});
  const tituloCatVehiculos = "Catálogo de Vehículos";
  const idModalDelete = "staticBackdrop";
  const pathAltaVehic = "/suvalsa/catalogos/vehiculos/alta";
  const pathCatVehic = "/suvalsa/catalogos/vehiculos";

  const title = "Eliminar Vehículo";
  const pendingText = "Eliminando vehículo, por favor espere.";
  const successText = `Vehículo ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };
  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "marca",
        header: "Marca",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "modelo",
        header: "Modelo",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "anio",
        header: "Año",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "placas",
        header: "Placas",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "chasis",
        header: "Chasis",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "motor",
        header: "Motor",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "noine",
        header: "No.INE",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "permisosct",
        header: "Permiso SCT",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "sust",
        header: "Sustancias",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "arrastre",
        header: "Arrastre",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "carga",
        header: "Carga",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "dimCaja",
        header: "Dim. Caja",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "dimUnidad",
        header: "Dim. Unidad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "terMarca",
        header: "Termo Marca",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "terModelo",
        header: "Termo Modelo",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "terSerie",
        header: "Termo Serie",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "rampaMarca",
        header: "Rampa Marca",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "rampaCap",
        header: "Rampa Cap",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "rampaSerie",
        header: "Rampa Serie",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "cajaMarca",
        header: "Caja Marca",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "cajaTipo",
        header: "Caja Tipo",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "cajaCap",
        header: "Caja Cap",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "cajaAcc",
        header: "Caja Accesorios",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "hidrolavadora",
        header: "Hidrolavadora",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Vehículos de" };

  const GetVehiculos = useCallback(async () =>{
    let rowsNew = [];
    console.log("obteniendo Datos de ws Vehiculos");
    const vehiculos = await ObtenerVehiculos();
    console.log("lista de Vehiculos: ", vehiculos);

    if (vehiculos) {
      rowsNew = vehiculos.map((res) => ({
        clave: res.CLAVE,
        id: res.id,
        marca: res.MARCA,
        modelo: res.MODELO,
        anio: res.AÑO,
        placas: res.PLACAS,
        chasis: res.CHASIS,
        motor: res.MOTOR,
        noine: res.NOINE,
        permisosct: res.SCT,
        sust: res.SUSTANCIAS,
        arrastre: res.ARRASTRE,
        carga: res.CARGA,
        dimCaja: res.DIMCAJA,
        dimUnidad: res.DIMUNIDAD,
        terMarca: res.TERMOMARCA,
        terModelo: res.TERMOMOD,
        terSerie: res.TERMOSERIE,
        rampaMarca: res.RAMPAMARCA,
        rampaCap: res.RAMPACAP,
        rampaSerie: res.RAMPASERIE,
        cajaMarca: res.CAJAMARCA,
        cajaTipo: res.CAJATIPO,
        cajaCap: res.CAJACAP,
        cajaAcc: res.CAJAACC,
        hidrolavadora: res.HIDROLAV
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarVehiculo(id);
  }

  return {
    tituloCatVehiculos,
    idModalDelete,
    pathAltaVehic,
    pathCatVehic,
    columnsData,
    opcionesTabla,
    GetVehiculos,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  };
};

export default useCatVehiculos;
