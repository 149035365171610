import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';

// Definir los estilos del PDF
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },
    text: {
        position: 'absolute',
        fontSize: 10,
    },
});

// Componentes para representar los textos con sus coordenadas
const AltaManifiestosPdf = ({ datos }) => (
    <Document>
        {datos.map((registro, index) => (
            <Page size="A4" style={styles.page}>
                <Text style={{ ...styles.text, top: 134.202, left: 69 }}>{registro.manifCliente}</Text>
                <Text style={{ ...styles.text, top: 134.202, left: 266 }}>TRAMITE</Text>
                <Text style={{ ...styles.text, top: 134.202, left: 503.64 }}>{registro.manifClave}</Text>
                <Text style={{ ...styles.text, top: 154.199, left: 225 }}>{registro.clienteNombre}</Text>
                <Text style={{ ...styles.text, top: 174.199, left: 120 }}>{registro.clienteCalle} {registro.clienteColonia} </Text>
                <Text style={{ ...styles.text, top: 191.199, left: 189.72 }}>{registro.clienteCiudad}, {registro.clienteEstado}</Text>
                <Text style={{ ...styles.text, top: 174.199, left: 503.16 }}>{registro.clienteCp}</Text>
                <Text style={{ ...styles.text, top: 211.199, left: 137 }}>{registro.clienteTelefono}</Text>

                <Text style={{ ...styles.text, top: 252.199, left: 70 }}>SANGRE BI 5 CULTIVOS Y CEPAS BI 1</Text>
                <Text style={{ ...styles.text, top: 252.199, left: 489 }}>Kg</Text>

                <Text style={{ ...styles.text, top: 272.199, left: 70 }}>PATOLÓGICO BI 3</Text>
                <Text style={{ ...styles.text, top: 272.199, left: 489 }}>Kg</Text>

                <Text style={{ ...styles.text, top: 292.199, left: 70 }}>NO ANÁTOMICO BI 4</Text>
                <Text style={{ ...styles.text, top: 292.199, left: 489 }}>Kg</Text>

                <Text style={{ ...styles.text, top: 312.199, left: 70 }}>PUNZOCORTANTE BI 2</Text>
                <Text style={{ ...styles.text, top: 312.199, left: 489 }}>Kg</Text>

                <Text style={{ ...styles.text, top: 460.199, left: 250.72 }}>Suvalsa SA de CV </Text>
                <Text style={{ ...styles.text, top: 480.199, left: 126.72 }}>José  Vasconcelos 39 Edif 1 Depto 004 Santísima Trinidad Tlalpan, México DF CP14429 (55) 54852800</Text>
                <Text style={{ ...styles.text, top: 500.199, left: 373.44 }}>09-I-18-18</Text>


                <Text style={{ ...styles.text, top: 557.112, left: 249 }}>{registro.manifFvisita}</Text>
                <Text style={{ ...styles.text, top: 591.202, left: 89.16 }}>México, Ciudad de México - Acapulco,Guerrero</Text>

                <Text style={{ ...styles.text, top: 642.202, left: 225 }}>SUVALSA, S.A. DE C.V.</Text>
                <Text style={{ ...styles.text, top: 661.202, left: 225 }}>12-II-01-2014</Text>
                <Text style={{ ...styles.text, top: 661.202, left: 350.44 }}>744 1267034</Text>
                <Text style={{ ...styles.text, top: 675.202, left: 126.72 }}>Carretera al Arenal km 1+500 Localidad El Bejuco, Acapulco de Juárez, Gro. CP 39931</Text>

                <Text style={{ ...styles.text, top: 740, left: 137 }}>Tomás Paz Valderrama</Text>
                <Text style={{ ...styles.text, top: 740, left: 350.44 }}>Gerente</Text>


            </Page>
        ))}
    </Document>
);

export default AltaManifiestosPdf;

// Para generar el PDF puedes utilizar este componente y renderizarlo con @react-pdf/renderer:
// pdf(<AltaManifiestosPdf />).toFile('output.pdf');
