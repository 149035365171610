//FetchUsers
import FetchApi, { FetchApiPost } from "./ConfigFetch";

const URL_USERS = 'http://suvalsa-home.ddns.net:8040/user/';
//const URL_USUARIOS = 'http://suvalsa-home.ddns.net:8091/usuariosLogin/'
const URL_USUARIOS = 'https://suvalsa-home.ddns.net:8091/usuariosLogin/'

const METHOD_ALTA = "crear";
const METHOD_EMAIL = 'email/';
const METHOD_VALIDAR = "validar";

export async function getUsersById(id) {
    let data = null

    data = await FetchApi(URL_USERS + id)

    return { data };
}

export async function getUsersByEmail(email) {
    let dataEmail = null
    dataEmail = await FetchApi(URL_USUARIOS.concat(METHOD_EMAIL) + email)
    console.log('dataEmail:', dataEmail)
    if (dataEmail.data !== null && dataEmail.data.length > 0)
        dataEmail = dataEmail.data

    console.log('let dataEmail=', dataEmail)
    return { dataEmail };
}

//POST Validacion de usuario
/**
 * Función para validar las credenciales del usuario.
 * @param {Object} credentials - Objeto que contiene 'email' y 'password'.
 * @returns {Object} - Respuesta del servidor.
 */
export async function validarUsuario(credentials) {
    let response = null;
    const jsonCredentials = JSON.stringify(credentials); // Serializamos el objeto a JSON
    response = await FetchApiPost(
        URL_USUARIOS.concat(METHOD_VALIDAR),
        jsonCredentials // Pasamos el JSON en lugar del objeto
    );

    return response; // Contiene 'headerResponse' y 'bodyResponse'
}

//POST
export async function AgregarUsuario(data) {
    let responseAlta = null;
    const jsonData = JSON.stringify(data); // Serializa a JSON
    responseAlta = await FetchApiPost(
        URL_USUARIOS.concat(METHOD_ALTA),
        jsonData // JSON serializado
    );

    return { responseAlta };
}

