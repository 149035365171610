import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import UseCatFrecuencias from "./UseFrecuencias";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoVisitas = () => {
  const {
    tituloCatFrecuencias,
    idModalDelete,
    pathAltaFrecVis,
    pathCatFrecVis,
    columnsData,
    opcionesTabla,
    GetFrecuencias,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = UseCatFrecuencias();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: visitas,
    setData: setVisitas,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetFrecuencias,
    idModalDelete,
    pathNavTo: pathAltaFrecVis,
    pathNavBack: pathCatFrecVis,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading||loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatFrecuencias}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={visitas}
            setData={setVisitas}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar frecuencia"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar la frecuencia con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoVisitas;
