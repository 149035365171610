import React from 'react'

const RegistroPagos = () => {
  return (
    <div>
      RegistroPagos
    </div>
  )
}

export default RegistroPagos
