import React from "react";
import { Form, Formik } from "formik";
import { InputText, Select as SelectOpt } from "../../../componentes/Formik";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import Accordion from "../../../componentes/Accordion/Accordion";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import schemaAltaDatosRuta from "../Schemas/SchemaAltaRutasDatos";
import useAltaRutas from "./UseAltaRutas";
import useAlta from "../../../Hooks/UseAltas";
import UseAgentesSelect from "../../../Hooks/UseAgentes";

const AltaRutaDatosForm = () => {

  const {
    initValuesAdd,
    AgregarDatosRuta,
    notification,
    agregarSelectNoEco,
    noEco,
    vehiculos,
    resetStates} = useAltaRutas();

  const { loadingAction, onSubmitAlta, buttonsGroup } = useAlta({
    addData: AgregarDatosRuta,
    notification,
    backUrl: null,
    actiosOK: resetStates
  });

  const{ agentes, gettingData, agregarSelectAg,
    idAgenteOper, operador,
    idAgenteAux, auxiliar,} = UseAgentesSelect()

    const renderAgente =(id, name, label, value, puesto, isSubmitting) => {
      return (
        <>
        <SelectOpt
                    id={id}
                    disabled={isSubmitting || gettingData}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label={label}
                    name={name}
                >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectAg(agentes, puesto)}
                </SelectOpt>
              {
                puesto === 'OPE' ?(
                  <InputText
                    value={value}
                    disabled={true}
                    label=""
                    name="operador"
                    type="text"
                    placeholder=""
                />
                ):(
                  <InputText
                    value={value}
                    disabled={true}
                    label=""
                    name="auxiliar"
                    type="text"
                    placeholder=""
                />
                )
              }
                
                </>
      )
    }
  return (
    <>
      {(gettingData || loadingAction) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAdd}
        validationSchema={schemaAltaDatosRuta}
        onSubmit={onSubmitAlta}
      >
        {(props) => {
          const { isSubmitting } = props;
          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos de la ruta"
                idTarget="collapseRutaDForm"
                expanded="true"
              >
                <div className=" grid grid-cols-1 md:grid-cols-4 col-start-2 items-start gap-2 w-full">
                  <InputText
                    disabled={isSubmitting}
                    label="Clave"
                    name="clave"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting}
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={isSubmitting}
                    label="Duración"
                    name="duracion"
                    type="number"
                    placeholder=""
                  />
                  <span />
                  {renderAgente(idAgenteOper, operador.name, operador.label, operador.value, 'OPE', isSubmitting)}
                  {renderAgente(idAgenteAux, auxiliar.name, auxiliar.label, auxiliar.value , 'AUX', isSubmitting)}
                  {/* <SelectOpt
                    id="selectOper"
                    disabled={isSubmitting}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Operador"
                    name="oper"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectAg(agentes, "OPE")}
                  </SelectOpt>

                  <InputText
                    value={oper}
                    disabled={true}
                    label="operador"
                    name="operador"
                    type="text"
                    placeholder=""
                  /> */}
                  {/* <SelectOpt
                    id="selectAux"
                    disabled={isSubmitting}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Auxiliar"
                    name="aux"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectAg(agentes, "AUX")}
                  </SelectOpt>
                  <InputText
                    value={aux}
                    disabled={true}
                    label=""
                    name="auxiliar"
                    type="text"
                    placeholder=""
                  /> */}
                  <SelectOpt
                    id="selectVehic"
                    disabled={isSubmitting}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="No.Eco."
                    name="noEco"
                  >
                    <option hidden defaultValue value=""></option>
                    {agregarSelectNoEco(vehiculos)}
                  </SelectOpt>
                  <InputText
                    value={noEco}
                    disabled={true}
                    label="Vehículo"
                    name="vehiculo"
                    type="text"
                    placeholder=""
                  />
                </div>
              </Accordion>

              <ButtonGroup buttonPrimary={buttonsGroup.buttonPrimary} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AltaRutaDatosForm;
