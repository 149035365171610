//import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgregarFrecuencia } from "../../../Apis/FetchCatalogos";
//import { sleep } from '../../../utils/CommonUtils'

export const notification = { title: "Alta de Frecuencia de Visitas", successText: 'Frecuencia agregada correctamente.' }

export const initValuesAfv = {
    clave: "",
    frecuencia: ""
};

export async function ImprimirContrato() {
    console.log("clic imprimir contrato frecuencia-visitas: ");
}

export async function CrearFrecuenciaVisita(values) {
    const datosFrec = {
        clave: values.clave,
        frecuencia: values.frecuencia
    };
    //await sleep(5000);
    await AgregarFrecuencia(datosFrec);
    return true;
}


export const urlCatalogo = "/suvalsa/catalogos/frecuencia-visitas";
