import React from "react";
import AltaDatosNumerosForm from "./AltaDatosNumeros.Form";
import CardNavigate from "../../../componentes/Cards/CardNavigate";

const AltaDatosNumeros = () => {
  const tituloANoDatos = "Alta de Números y Datos de Control";

  return (
    <CardNavigate title={tituloANoDatos}>
      <AltaDatosNumerosForm />
    </CardNavigate>
  );
};

export default AltaDatosNumeros;
