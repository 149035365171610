import React from 'react'

const GastosMtto = () => {
  return (
    <div>
      GastosMtto
    </div>
  )
}

export default GastosMtto
