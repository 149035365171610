import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaTrabajo = Yup.object({
  clave: Yup.string()
    .required(MSJ_DATO_REQ),
  trabajo: Yup.string()
  .required(MSJ_DATO_REQ),
});

export default schemaAltaTrabajo;
