import React from 'react'

const ReportesCuentas = () => {
  return (
    <div>
      ReportesCuentas
    </div>
  )
}

export default ReportesCuentas
