import { useCallback, useMemo, useState } from "react";
import { EliminarAgente, ObtenerAgentes } from "../../../Apis/FetchCatalogos";
import { columnSize } from "../../../Constantes";
//import { sleep } from "../../../utils/CommonUtils";

const useCatPersonal = () => {
  const [dataRowSelection, setDataRowSelection] = useState({});

  const tituloCatAgentes = "Catálogo de Personal";
  const idModalDelete = "staticBackdrop";
  const pathAltaPersonal = "/suvalsa/catalogos/personal/alta";
  const pathCatPersonal = "/suvalsa/catalogos/personal";

  const title = "Eliminar Agente";
  const pendingText = "Eliminando agente, por favor espere.";
  const successText = `Agente ${dataRowSelection[0]?.clave} eliminado correctamente.`;
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  const notification = {
    title,
    pendingText,
    successText,
    errorText
  };

  const columnsData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "puesto",
        header: "Puesto",
        enableColumnFilter: false,
        enableSorting: true,
        size: 50 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "tarjeta",
        header: "Tarjeta",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "domicilio",
        header: "Domicilio",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "telefono",
        header: "Teléfono",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "celular",
        header: "Celular",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "licencia",
        header: "Licencia",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const opcionesTabla = { ofText: "Agentes de" };

  const GetAgentes = useCallback(async () =>{
    let rowsNew = [];
    const agentes = await ObtenerAgentes();
    console.log("lista de Agentes: ", agentes);

    if (agentes) {
      rowsNew = agentes.map((ag) => ({
        clave: ag.clave,
        id: ag.id,
        nombre: ag.nombre,
        puesto: ag.puesto,
        tarjeta: ag.tarjeta,
        domicilio: ag.domicilio,
        telefono: ag.telefono,
        celular: ag.celular,
        licencia: ag.licencia
      }));
    }
    return rowsNew;
  },[]);

  async function actionDelete(id) {
    console.log("actionDelete");
    await EliminarAgente(id);
  }

  return {
    tituloCatAgentes,
    columnsData,
    opcionesTabla,
    GetAgentes,
    idModalDelete,
    pathAltaPersonal,
    pathCatPersonal,
    actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  };
};

export default useCatPersonal;
