import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";
const regexDigit = /^[0-9]+$/;

const schemaAltaNoDatos = Yup.object({
  clave: Yup.string()
    .matches(regexDigit, "Clave incorrecta")
    .required(MSJ_DATO_REQ),
  status: Yup.string().required(MSJ_DATO_REQ),
  notas: Yup.string().required(MSJ_DATO_REQ),
  dato: Yup.string().required(MSJ_DATO_REQ),
  concepto: Yup.string().required(MSJ_DATO_REQ)
});

export default schemaAltaNoDatos;
