import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import CustomModal from "../../../componentes/Modals/CustomModal";
import useCatResiduos from "./UseResiduos";
import useCatalogo from "../../../Hooks/UseCatalogos";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoResiduos = () => {
  const {
    tituloCatResiduos,
    columnsData,
    opcionesTabla,
    GetResiduos,
    idModalDelete,
    pathAltaResid,
    pathCatResid,
    actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  } = useCatResiduos();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: residuos,
    setData: setResiduos,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetResiduos,
    idModalDelete,
    pathNavTo: pathAltaResid,
    pathNavBack: pathCatResid,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatResiduos}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={residuos}
            setData={setResiduos}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            id={idModalDelete}
            title={"Eliminar residuo"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar el Residuo con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoResiduos;
