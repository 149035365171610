import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaAltaFrecuencias = Yup.object({
  clave: Yup.string().required(MSJ_DATO_REQ),
  frecuencia: Yup.string().required(MSJ_DATO_REQ)
});

export default schemaAltaFrecuencias;
