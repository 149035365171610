import React from "react";
import CardNavigate from "../../../componentes/Cards/CardNavigate";
import AltaResiduoForm from "./AltaResiduo.Form";

const AltaResiduo = () => {
  const tituloAResiduo = "Alta de Residuo";
  return (
    <CardNavigate title={tituloAResiduo}>
      <AltaResiduoForm />
    </CardNavigate>
  );
};

export default AltaResiduo;
