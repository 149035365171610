import CardNavigate from "../../../componentes/Cards/CardNavigate";
import { ButtonGroup } from "../../../componentes/Button/GenericButton";
import CustomModal from "../../../componentes/Modals/CustomModal";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import useCatalogo from "../../../Hooks/UseCatalogos";
import useCatFamilias from "./UseFamilias";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";

const CatalogoFamilias = () => {
  const {
    tituloCatFamilias,
    idModalDelete,
    pathAltaFam,
    pathCatFam,
    columnsData,
    opcionesTabla,
    GetFamilias,
    dataRowSelection,
    setDataRowSelection,
    actionDelete,
    notification
  } = useCatFamilias();

  const {
    loading,
    loadingAction,
    optionsTable,
    data: familias,
    setData: setFamilias,
    GetRowsData,
    rowSelection,
    setRowSelection,
    getDataSelected,
    confirmarEliminacion,
    buttonsGroup
  } = useCatalogo({
    optionsTable: opcionesTabla,
    getData: GetFamilias,
    idModalDelete,
    pathNavTo: pathAltaFam,
    pathNavBack: pathCatFam,
    deleteData: actionDelete,
    dataRowSelection,
    setDataRowSelection,
    notification
  });

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <CardNavigate title={tituloCatFamilias}>
        <div className="flex flex-col w-full gap-2">
          <CustomGenericTable
            data={familias}
            setData={setFamilias}
            getData={GetRowsData}
            optionsTable={optionsTable}
            columnsData={columnsData}
            isTableSelected={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            setDataRowSelection={setDataRowSelection}
          />
          <ButtonGroup
            buttonPrimary={buttonsGroup.buttonPrimary}
            buttonSecondary={buttonsGroup.buttonSecondary}
            buttonTertiary={buttonsGroup.buttonTertiary}
          />
          <CustomModal
            title={"Eliminar familia"}
            actionAccept={confirmarEliminacion}
          >
            <span>¿Deseas eliminar la Familia con </span>
            <h1>clave {getDataSelected()?.clave ?? ""}?</h1>
          </CustomModal>
        </div>
      </CardNavigate>
    </>
  );
};

export default CatalogoFamilias;
