import React from "react";
import { RemoveScroll } from "react-remove-scroll";

const CircleSpiner = () => {
  return (
    <RemoveScroll>
      <div className="loader-container">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default CircleSpiner;
