import FetchApi, { FetchApiDelete, FetchApiPost, FetchApiPut } from "./ConfigFetch";

const URL_SALIDAS = "https://suvalsa-home.ddns.net:8030/salidas/";
const METHOD_ALTA = "crear";

export async function ObtenerMaxSalida() {
  let max = "";

  max = await FetchApi(URL_SALIDAS.concat("maxnumero"));

  return max.data;
}

//DELETE
//https://suvalsa-home.ddns.net:8030/salidas/{id}
export async function EliminarSalida(id) {
  let salida = "";

  salida = await FetchApiDelete(URL_SALIDAS.concat(id));

  return salida.data;
}
//GET
//https://suvalsa-home.ddns.net:8030/salidas/manifiesto/307340/486879
//   [
//     {
//         "numero": "99999",
//         "fecha": "2016-08-01",
//         "tipo": "P",
//         "insumo": "12",
//         "cantidad": 3.00,
//         "unidad": "Pz",
//         "referencia": "SEPT 2016",
//         "familia": "35",
//         "cliente": "3925",
//         "manif": "35",
//         "factura": "",
//         "usom": "",
//         "status": ""
//     },
//     {
//         "numero": "99999",
//         "fecha": "2016-08-01",
//         "tipo": "P",
//         "insumo": "12",
//         "cantidad": 3.00,
//         "unidad": "Pz",
//         "referencia": "SEPT 2016",
//         "familia": "35",
//         "cliente": "3925",
//         "manif": "35",
//         "factura": "",
//         "usom": "",
//         "status": ""
//     }
// ]
export async function ObtenerSalidasManif(numero, manifiesto) {
  let salida = "";

  salida = await FetchApi(URL_SALIDAS.concat('manifiesto/' + numero + "/" + manifiesto));

  return salida.data;
}
//https://suvalsa-home.ddns.net:8030/salidas/crear
// {
//     "numero": "10000",
//     "fecha": "2024-05-10",
//     "tipo": "A",
//     "insumo": "INS1",
//     "cantidad": 150.00,
//     "unidad": "KG",
//     "referencia": "REF1234567",
//     "familia": "FAM1",
//     "cliente": "CLI1",
//     "manif": "MANIF1234567",
//     "factura": "FACT1",
//     "usom": "UM",
//     "status": "1"
//   }  
//POST
export async function CrearSalida(data) {
  let responseFam = null;
  responseFam = await FetchApiPost(
    URL_SALIDAS.concat(METHOD_ALTA),
    JSON.stringify(data)
  );

  if (!responseFam.headerResponse.statusOk) {
    throw new Error(responseFam.bodyResponse.error)
  }

  return responseFam;
}


//{numero}/{manif}
export async function ActualizarSalida(noSalida,noManifiesto,data) {
  let responseUpdateSal = null;
  responseUpdateSal = await FetchApiPut(URL_SALIDAS.concat(noSalida+'/'+noManifiesto), JSON.stringify(data))

  if (!responseUpdateSal.headerResponse.statusOk) {
    throw new Error(responseUpdateSal.bodyResponse.error)
  }

  return responseUpdateSal;
}