import React from 'react'

const ReportesManifiestos = () => {
  return (
    <div>
      ReportesManifiestos
    </div>
  )
}

export default ReportesManifiestos
